<template>
  <div>
    <head>
      <meta name="referrer" content="no-referrer-when-downgrade">
      <meta http-equiv="Content-Security-Policy" content="default-src 'self'; style-src 'self' 'unsafe-inline' http://fonts.googleapis.com; font-src 'self' http://fonts.gstatic.com">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
    </head>
    <header class="header">
      <div class="logo">
        <img :src="logo" alt="Crypto Fundamentalist Logo" class="cf-coin"> 
      </div>
      <nav>
        <ul>
          <template v-if="isAuthenticated">
            <li><a href="#" @click="logout">Logout</a></li>
          </template>
          <template v-else>
            <li><router-link to="/login">Login</router-link></li>
            <li><router-link to="/register">Register</router-link></li>
          </template>
        </ul>
      </nav>
    </header>
    <main class="flex-fill"> <!-- Add the class flex-fill to the main element -->
      <div class="flashes" v-if="messages.length > 0">
        <ul>
          <li v-for="message in messages" :key="message">{{ message }}</li>
        </ul>
      </div>
      <router-view/>
    </main>
    <footer class="footer">
      <p>&copy; cryptofundamentalist.com 2024</p>
    </footer>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';
import logo from '@/assets/CF_coin_logo.png';

export default {
  name: 'BaseLayout',
  setup() {
    const store = useStore(); // Access the Vuex store
    const router = useRouter();

    const isAuthenticated = computed(() => store.state.isAuthenticated);
    const messages = computed(() => store.state.flashMessages);

    const logout = async () => {
      try {
        // Get the CSRF token and session token from Vuex
        const csrfToken = store.getters.csrfToken;
        const sessionToken = store.getters.sessionToken;

        // Set the headers for the logout request
        const headers = {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
          'Authorization': `Bearer ${sessionToken}`,
        };

        // Make the logout API request using Axios
        await axios.post('http://localhost:5000/api/logout', null, { headers, withCredentials: true });

        // Clear the user session and any other logout-related tasks from the Vuex store
        await store.dispatch('logout');

        // Set isAuthenticated to false after successful logout
        store.commit('setAuthentication', false);

        // Redirect to the home page (or any desired route)
        router.push('/');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Logout error:', error);
      }
    };

    return {
      isAuthenticated,
      messages,
      logout,
    };
  },
  data() {
    return {
      logo: logo,
    }
  },
};

</script>


<style scoped>
/* Add your styles here */
body {
    margin: 10px;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
}

.header {
    background-color: #384d56;
    color: #fff;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    z-index: 10000;
    position: relative;
}
.cf-coin {
  height: 55px; /* Adjust the size as needed */
  margin-right: 20px; /* Adjust spacing as needed */
}
.logo {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  font-size: 17px;
  text-shadow: #b8969bf5;
  color: #d1cacbf5;
  padding: 10px;
}

nav {
  display: flex;
  padding: 0 10px;
}

nav ul {
  list-style: none;
  display: flex;
  padding: 0 10px;
}

nav li {
  margin: 0 10px;
  padding: 20px;
}

nav a {
  color: #d1cacbf5;
  text-decoration: none;
  font-size: 15px;
  font-weight: bolder;
}

main {
  padding: 10px;
  margin: 10px
}

.footer {
  background-color: #384d56;
  padding: 10px;
  text-align: center;
  color: #d1cacbf5;
  z-index: 50000;
  position: relative;
}

.flex-fill {
  flex: 1; /* This will make the main component fill the remaining space */
}
</style>