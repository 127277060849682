<template>
  <div class="news-card-container" @click="onComponentClicked" v-if="newsCardGenerated && Object.keys(newsCardGenerated).length">
    <Vue3DraggableResizable class="draggable"
      :style="{ zIndex: getComponentZIndex }"
      :resizable="false" 
      :draggable="isGlobalDraggable" 
      :dragHandle="'.drag-handle'"
      :x="400"
      :y="300"
      >
      <div v-if="selectedNews[keyword]" class="news-card">
        <h3 class="card-header">News card - "{{ keyword }}"</h3>
        <button class="close-button" @click="closeCard">X</button>
        <h2 class="card-title">{{ selectedNews[keyword].title }}</h2>
        <p class="card-author-source">
          <span class="author">{{ selectedNews[keyword].author }}</span>
          <span class="source">{{ selectedNews[keyword].source }}</span>
        </p>
        <p class="published-at">
          <span class="date">{{ selectedNews[keyword].published_at }}</span>
        </p>
        <img v-if="selectedNews[keyword].image_url" :src="selectedNews[keyword].image_url" alt="News Image" class="card-image" />
        <p class="card-description">{{ selectedNews[keyword].description }}</p>
        <button class="next-article-button" @click="loadNextArticle">Next Article</button>
        <br/>
        <p class="read-more-link"><a :href="selectedNews[keyword].url" target="_blank">Click here to read at the source</a></p>
      </div>
    </Vue3DraggableResizable>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Vue3DraggableResizable from 'vue3-draggable-resizable';
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css';

export default {
  props: {
    onClose: Function,
    keyword: String,
  },
  
  computed: {
    ...mapState('news', ['selectedNews', 'newsCardGenerated', 'currentKeywords']),
    ...mapState(['isGlobalDraggable']),
    ...mapState('components', ['openedComponents']),
    getComponentZIndex() {
      const componentId = this.keyword ? `news-card-${this.keyword}` : null;
      const component = componentId ? this.openedComponents.find(c => c.id === componentId) : null;
      return component ? component.zIndex : 0;
    },
  },

  components: {
    Vue3DraggableResizable,
  },

  methods: {
    ...mapActions('components', ['bringToFront', 'addComponent', 'removeComponent', 'incrementCounter', 'decrementCounter']),
    closeCard() {
      // Emit an event to notify the parent component to close the card
      this.$store.commit('components/removeComponent', { id: `news-card-${this.keyword}` });
      this.$store.commit('components/decrementCounter');
      this.$emit('close', this.keyword);
    },
    onComponentClicked() {
      const componentId = this.keyword ? `news-card-${this.keyword}` : null;
      this.$store.commit('components/bringToFront', componentId);
    },
    loadNextArticle() {
      this.$store.dispatch('news/selectNextNews', this.keyword);
    },
  },
  watch: {
    newsCardGenerated(newVal) {
      console.log('newsCardGenerated', newVal);
    },
  },
  async mounted() {
    console.log('NewsCard mounted');
    if (this.selectedNews) {
      const componentId = `news-card-${this.keyword}`;
      const component = {
        id: componentId,
        zIndex: this.$store.state.components.topZIndex,
        type: 'news-card',
        keyword: this.keyword
      };
      const canAddComponent = await this.$store.dispatch('components/addComponentIfAllowed', component);
      if (canAddComponent) {
        this.$store.commit('components/bringToFront', componentId);
      }
      else {
        console.log('Cannot add component do to max limit reached.');
      }
    }
  },

  beforeUnmount() {
    if (this.selectedNews) {
      const componentId = `news-card-${this.keyword}`;
      this.$store.commit('components/removeComponent', componentId);
//      this.$store.commit('components/decrementCounter');
    }
  },

  created() {
    console.log('NewsCard created');
  }
};
</script>

<style scoped>
.draggable{
  width: 400px;
  max-height: 450px;
  min-height: 450px;
}

.news-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 450px;
  min-height: 450px;
}

.news-card {
  background-color: #fff;
  padding: 20px;
  border: 3px solid #384d56;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.close-button {
  position: absolute;
  top: 7px;
  right: 7px;
  background-color: #c63745;
  color: #fff;
  border: none;
  padding: 10px 10px;
  border-radius: 15%;
  cursor: pointer;
}

.card-title {
  font-size: 17px;
  font-weight: semi-bold;
  margin-bottom: 10px;
}

.card-author-source {
  font-size: 12px;
  font-style: italic;
  margin-bottom: 15px;
}

.published-at {
  font-size: 10px;
  margin-top: 10px;
}

.card-image {
  max-width: 100px;
  max-height: 100px;
  height: auto;
  margin-bottom: 15px;
  position: center;
}

.card-description {
  font-size: 14px;
  text-align: justify;
  margin-bottom: 15px;
}

.read-more-link {
  color: #c63745;
  font-size: 10px;
}

.next-article-button {
  display: block;
  background-color: #384d56;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
  position: center;
  width: 200px;
}

.next-article-button:hover {
  background-color: #c63745;
  transform: scale(1.02);
}

</style>