<template>
  <div class="container mt-4">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h1>Email Verification</h1>
        <hr>
        <div v-if="loading">
          <p>Verifying your email, please wait...</p>
        </div>
        <div v-else>
          <p v-if="success">Email verification successful! You can now log in.</p>
          <p v-else-if="expired">Verification token has expired. Please request a new verification email.</p>
          <p v-else>Invalid verification token.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      loading: true,
      success: false,
      expired: false,
    };
  },
  created() {
    // Fetch the verification token from the URL params
    const token = this.$route.params.token;

    // Call the API endpoint for verification using Axios or fetch
    axios.get(`verification/${token}`)
      .then((response) => {
        // Handle the response from the backend API
        if (response.data.success) {
          this.success = true;
        } else if (response.data.expired) {
          this.expired = true;
        }
        this.loading = false;
      })
      .catch((error) => {
        // Handle any errors
        this.loading = false;
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
};
</script>

<style>
/* Add any custom styles for the EmailVerification component here */
</style>
