<template>
  <BaseBox :isOpen="isOpen" :railItem="railItem" @close="closeBox">
    <div class="search-box">
      <div class="search-group">
        <h3 class="section-title">Search Engine</h3>
        <p class="search-description">Your query can be a single word or an elaborated question.</p>
        
        <hr class="divider" />
        
        <textarea
          class="search-input"
          v-model="searchQuery"
          @keyup.enter="triggerSearch"
          placeholder="Enter search query..."
          :disabled="!isOpen"
          rows="2"
        ></textarea>

        <div class="index-options">
          <div class="option-row">
            <p>All</p>
            <label class="switch smaller">
              <input type="checkbox" v-model="selectedIndexes.all" @change="toggleAll($event.target.checked)" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="option-row">
            <p>White Papers Summaries</p>
            <label class="switch smaller">
              <input type="checkbox" v-model="selectedIndexes.wpSummaries" @change="toggleIndex('wpSummaries', $event.target.checked)" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="option-row">
            <p>Contextualized Q&A</p>
            <label class="switch smaller">
              <input type="checkbox" v-model="selectedIndexes.generalInfo" @change="toggleIndex('generalInfo', $event.target.checked)" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        
        <div class="llm-toggle">
          <br>
          <p>Use LLM with the query results:</p>
          <label class="switch">
            <input type="checkbox" v-model="useLLM">
            <span class="slider round"></span>
          </label>
        </div>

        <hr class="divider" />
        
        <button
          class="search-button"
          @click="triggerSearch"
          :disabled="!isOpen"
        >
          Search
        </button>
      </div>
    </div>
  </BaseBox>
</template>

  
  <script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import BaseBox from './BaseBox.vue';
  
  export default {
    components: {
      BaseBox,
    },
    props: {
      isOpen: Boolean,
    },
    data() {
      return {
        railItem: { title: 'Search' },
        searchQuery: '',
        selectedIndexes: {
          all: true,
          wpSummaries: false,
          generalInfo: false,
        },
        useLLM: false,
      };
    },
    computed: {
      ...mapState('rail', ['selectedRailItem']),
      selectedSearchType: {
        get() {
          const indexes = this.selectedIndexes;
          return Object.keys(indexes).filter(key => indexes[key]);
        },
        set(value) {
          let indexes = Object.keys(this.selectedIndexes);
          indexes.forEach(index => {
            this.selectedIndexes[index] = value.includes(index);
          });
        }
      }
    },

    watch: {
      'selectedIndexes.all'(newVal) {
        if (newVal) {
          this.resetIndexesExceptAll();
        }
      },
      selectedSearchType(newValues) {
        if (newValues.length > 1 && newValues.includes('all')) {
          // When "all" and other indexes are selected, turn off "all"
          this.selectedIndexes.all = false;
        }
      }
    },
    
    methods: {
      ...mapActions('search', ['performSearch', 'setSearchOptions', 'addToQueryHistory' ]),
      ...mapMutations('rail', ['closeBox']),
      async triggerSearch() {
        if (!this.searchQuery) return;
        const payload = {
            query: this.searchQuery,
            searchType: this.selectedIndexes, // Make sure this is the correct variable for selected indexes
            useLLM: this.useLLM
        };
        await this.$store.dispatch('search/performSearch', payload);
        this.setSearchOptions({
            selectedIndexes: this.selectedIndexes,
            useLLM: this.useLLM,
        });
        // The response handling seems to be static, consider if you need to adjust it based on new payload structure
        const firstGeneralResult = this.$store.state.search.generalResults[0];
        const firstBookResult = this.$store.state.search.bookResults[0];
        if (firstGeneralResult) {
            this.$store.dispatch('search/setSelectedSearchId', firstGeneralResult.id);
        }
        if (firstBookResult) {
            this.$store.dispatch('search/setSelectedSearchId', firstBookResult.id);
        }
        this.$emit('searchInitiated');
        // Assuming setSearchOptions is a method to update the state of search options in your store
        this.setSearchOptions({
            selectedIndexes: this.selectedIndexes,
            useLLM: this.useLLM,
        });
      },
      toggleAll(checked) {
        // If "All" is checked, turn off other filters
        if (checked) {
          this.selectedIndexes.wpSummaries = false;
          this.selectedIndexes.generalInfo = false;
        }
        this.selectedIndexes.all = checked;
      },

      toggleIndex(index, checked) {
        // If any specific index is turned on, turn off "All"
        if (checked) {
          this.selectedIndexes.all = false;
        }
        this.selectedIndexes[index] = checked;
      },
      resetIndexesExceptAll() {
        this.selectedIndexes.wpSummaries = false;
        this.selectedIndexes.generalInfo = false;
      },
    },
  };
</script>


<style scoped>
.search-box {
  background-color: #fff;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #384d56;
  padding: 20px;
  font-size: 14px; /* Smaller font size */
  font-style: italic; /* Italic font style */
}

.search-group {
  display: block;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

.section-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: black;
}

.search-description {
  font-size: 14px; /* Smaller font size */
  font-style: italic; /* Make text italic */
  margin-bottom: 10px; /* Add some space below the paragraph */
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px; /* Adjust font size as desired */
  resize: vertical; /* Allows the textarea to be resizable vertically */
  overflow-y: auto; /* Ensures content can scroll */
}

.search-button {
  width: 100%;
  background-color: #384d56;
  color: #fff;
  border: none;
  padding: 12px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.search-button:hover {
  background-color: #c63745;
}

.divider {
  border: none;
  border-top: 2px solid #ccc; /* Corrected to ensure visibility */
  margin: 20px 0;
}

/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #384d56;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #c63745;;
}

input:focus + .slider {
  box-shadow: 0 0 1px #384d56;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.option-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.switch.smaller {
  width: 36px; /* 60% of the original 60px width */
  height: 20px; /* Adjust height accordingly */
}

.switch.smaller .slider {
  width: 36px;
  height: 20px;
}

.switch.smaller .slider:before {
  width: 18px;
  height: 18px;
}

.switch.smaller input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
