<template>
  <BaseBox :railItem="railItem" :isOpen="isOpen" @close="closeBox">
    <div class="settings-box">
      <div class="settings-group">
        <h3 class="section-title">Display Settings</h3>
        <button
          class="Change-Display-Settings"
          @click="openDisplayCard"
          :disabled="!isOpen"
        >
          Change Display Settings
        </button>
      </div>
      <hr class="divider" />
    </div>
  </BaseBox>
  <SettingsDisplayCard v-if="isSettingsDisplayCardOpen" @option-selected="handleOptionSelected" />
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SettingsDisplayCard from './SettingsDisplayCard.vue';
import BaseBox from './BaseBox.vue';

export default {
  data() {
    return {
      railItem: { title: 'Settings' }, // Define railItem here
    };
  },
  props: {
    isOpen: Boolean,
  },
  components: {
    SettingsDisplayCard,
    BaseBox,
  },
  computed: {
    ...mapState('rail', ['selectedRailItem']),
    ...mapState('settings', ['isSettingsDisplayCardOpen']),
  },
  methods: {
    ...mapMutations('rail', ['closeBox']),
    openDisplayCard() {
      this.$store.dispatch('settings/setSettingsDisplayCardOpen', true);
    },
  },
};
</script>

<style scoped>
.settings-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #384d56;
}

.section-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.Change-Display-Settings {
  background-color: #384d56;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.Change-Display-Settings:hover {
  background-color: #c63745;
}

.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}


</style>