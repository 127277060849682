<template>
  <div class="container mt-4">
    <div class="row justify-content-center">
      <div class="col-md-6">

        <h1>Registration</h1>
        <hr><br><br>

        <form @submit="register" class="registration-form">

          <h2>Required Information</h2><br>
          <hr><br>
          <div class="form-group">
            <div class="mb-3">
              <label for="name">Name:</label>
              <input type="text" id="name" v-model="name" class="form-control" placeholder="" required>
              <span v-for="(error, index) in formErrors.name" :key="index" class="invalid-feedback">{{ error }}</span>
            </div>
          </div>

          <div class="form-group">
            <label for="username">Username:</label>
            <input type="text" id="username" v-model="username" class="form-control" placeholder="" required>
            <span v-for="(error, index) in formErrors.username" :key="index" class="invalid-feedback">{{ error }}</span>
          </div>

          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="email" class="form-control" placeholder="" required>
            <span v-for="(error, index) in formErrors.email" :key="index" class="invalid-feedback">{{ error }}</span>
          </div>

          <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" id="password" v-model="password" class="form-control" placeholder="" required>
            <span v-for="(error, index) in formErrors.password" :key="index" class="invalid-feedback">{{ error }}</span>
          </div>

          <div class="form-group">
            <label for="confirmPassword">Confirm Password:</label>
            <input type="password" id="confirmPassword" v-model="confirmPassword" class="form-control" placeholder="" required>
            <span v-for="(error, index) in formErrors.confirmPassword" :key="index" class="invalid-feedback">{{ error }}</span>
          </div>

          <br>
          <hr>
          <br>

          <h2>Groups of Interest</h2>
          <p>It allows us to taylor AI curated content based on what matters to you</p>
          <hr><br>

          <div class="form-group">
            <br>
            <h3><label for="investingInterests">Investing:</label></h3>
            <br>
            <div class="checkbox-list">
              <br>
              <label v-for="(option, index) in investingChoices" :key="index" class="checkbox-label">
                <input type="checkbox" :value="option.value" v-model="investingInterests">
                {{ option.label }}
              </label>
            </div>
            <br>
            <textarea v-model="investingOther" class="form-control" placeholder="Comma-separated additional interests"></textarea>
            <br>
          </div>

          <div class="form-group">
            <br>
            <h3><label for="tradingInterests">Cryptocurrency Trading:</label></h3>
            <br>
            <div class="checkbox-list">
              <br>
              <label v-for="(option, index) in tradingChoices" :key="index" class="checkbox-label">
                <input type="checkbox" :value="option.value" v-model="tradingInterests">
                {{ option.label }}
              </label>
            </div>
            <br>
            <textarea v-model="tradingOther" class="form-control" placeholder="Comma-separated additional interests"></textarea>
            <br>
          </div>

          <div class="form-group">
            <br>
            <h3><label for="technologyInterests">Technology:</label></h3>
            <br>
            <div class="checkbox-list">
              <br>
              <label v-for="(option, index) in technologyChoices" :key="index" class="checkbox-label">
                <input type="checkbox" :value="option.value" v-model="technologyInterests">
                {{ option.label }}
              </label>
            </div>
            <br>
            <textarea v-model="technologyOther" class="form-control" placeholder="Comma-separated additional interests"></textarea>
            <br>
          </div>

          <div class="form-group">
            <br>
            <h3><label for="applicationsInterests">Applications:</label></h3>
            <br>
            <div class="checkbox-list">
              <br>
              <label v-for="(option, index) in applicationsChoices" :key="index" class="checkbox-label">
                <input type="checkbox" :value="option.value" v-model="applicationsInterests">
                {{ option.label }}
              </label>
            </div>
            <br>
            <textarea v-model="applicationsOther" class="form-control" placeholder="Comma-separated additional interests"></textarea>
            <br>
          </div>

          <div class="form-group">
            <br>
            <h3><label for="platformsInterests">Platforms:</label></h3>
            <br>
            <div class="checkbox-list">
              <br>
              <label v-for="(option, index) in platformsChoices" :key="index" class="checkbox-label">
                <input type="checkbox" :value="option.value" v-model="platformsInterests">
                {{ option.label }}
              </label>
            </div>
            <br>
            <textarea v-model="platformsOther" class="form-control" placeholder="Comma-separated additional interests"></textarea>
            <br>
          </div>

          <div class="form-group">
            <br>
            <h3><label for="dataInterests">Data Analysis:</label></h3>
            <br>
            <div class="checkbox-list">
              <br>
              <label v-for="(option, index) in dataChoices" :key="index" class="checkbox-label">
                <input type="checkbox" :value="option.value" v-model="dataInterests">
                {{ option.label }}
              </label>
            </div>
            <br>
            <textarea v-model="dataOther" class="form-control" placeholder="Comma-separated additional interests"></textarea>
            <br>
          </div>

          <div class="form-group">
            <br>
            <h3><label for="nftsInterests">NFTs:</label></h3>
            <br>
            <div class="checkbox-list">
              <br>
              <label v-for="(option, index) in nftsChoices" :key="index" class="checkbox-label">
                <input type="checkbox" :value="option.value" v-model="nftsInterests">
                {{ option.label }}
              </label>
            </div>
            <br>
            <textarea v-model="nftsOther" class="form-control" placeholder="Comma-separated additional interests"></textarea>
            <br>
          </div>

            <!-- Use v-for to loop through formErrors and display error messages -->
            <span
              v-for="(errors, field) in formErrors"
              :key="field"
              class="invalid-feedback"
            >
              {{ errors.join(', ') }}
            </span>

            <!-- Submit button -->
          <button type="submit" class="btn btn-primary">Register</button>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, reactive, toRefs, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { instance } from '../../main'

// Utility function to convert camelCase to snake_case
function camelToSnake(str) {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export default {
  setup() {
    const router = useRouter();
    const store = useStore(); // Get the Vuex store instance
    const isAuthenticated = computed(() => store.state.isAuthenticated);
    const csrfToken = computed(() => store.state.csrfToken);
    const sessionToken = computed(() => store.state.sessionToken);

    const investingChoices = ref([
      { value: 1, label: 'Bitcoin' },
      { value: 2, label: 'Ethereum' },
      { value: 3, label: 'Altcoins' },
      { value: 4, label: 'ICO/IEO/IDO' }
    ]);
    const tradingChoices = ref([
      { value: 1, label: 'Technical Analysis' },
      { value: 2, label: 'Fundamental Analysis' },
      { value: 3, label: 'Leverage Trading' },
      { value: 4, label: 'Risk Management' }
    ]);
    const technologyChoices = ref([
      { value: 1, label: 'Consensus Algorithms' },
      { value: 2, label: 'Smart Contracts' },
      { value: 3, label: 'Layer 2 Solutions' },
      { value: 4, label: 'Privacy Protocols' }
    ]);
    const applicationsChoices = ref([
      { value: 1, label: 'Finance' },
      { value: 2, label: 'Supply Chain' },
      { value: 3, label: 'Identity Management' },
      { value: 4, label: 'Healthcare' }
    ]);
    const platformsChoices = ref([
      { value: 1, label: 'Ethereum' },
      { value: 2, label: 'Binance Smart Chain' },
      { value: 3, label: 'Cardano' },
      { value: 4, label: 'Solana' }
    ]);
    const dataChoices = ref([
      { value: 1, label: 'Market Data' },
      { value: 2, label: 'On-chain Analytics' },
      { value: 3, label: 'Social Sentiment Analysis' },
      { value: 4, label: 'GitHub Activity' }
    ]);
    const nftsChoices = ref([
      { value: 1, label: 'Art' },
      { value: 2, label: 'Collectibles' },
      { value: 3, label: 'Gaming' },
      { value: 4, label: 'Virtual Reality' },
      { value: 5, label: 'Real Estate' },
      { value: 6, label: 'Domain Names' },
      { value: 7, label: 'Alternative Assets' }
    ]);

    const formData = reactive({
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      investingInterests: [],
      investingOther: '',
      tradingInterests: [],
      tradingOther: '',
      technologyInterests: [],
      technologyOther: '',
      applicationsInterests: [],
      applicationsOther: '',
      platformsInterests: [],
      platformsOther: '',
      dataInterests: [],
      dataOther: '',
      nftsInterests: [],
      nftsOther: ''
    });

    const formErrors = ref({
      name: [],
      username: [],
      email: [],
      password: [],
      confirmPassword: [],
      investingInterests: [],
      investingOther: [],
      tradingInterests: [],
      tradingOther: [],
      technologyInterests: [],
      technologyOther: [],
      applicationsInterests: [],
      applicationsOther: [],
      platformsInterests: [],
      platformsOther: [],
      dataInterests: [],
      dataOther: [],
      nftsInterests: [],
      nftsOther: []
    });

    const register = async (event) => {
      event.preventDefault();
      if (await validateForm()) {
        try {
          // Convert camelCase keys to snake_case keys
          const snakeFormData = {};
          for (const [key, value] of Object.entries(formData)) {
            snakeFormData[camelToSnake(key)] = value;
          }
          const response = await instance.post('/api/register', snakeFormData, {
            headers: {
              'X-CSRFToken': csrfToken.value,
              'Content-Type': 'application/json',
            },
          });

          if (response && response.data && response.data.errors) {
            formErrors.value = {
              username: response.data.errors.username,
              email: response.data.errors.email,
              password: response.data.errors.password,
              name: response.data.errors.name,
              investingInterests: response.data.errors.investing_interests,
              investingOther: response.data.errors.investing_other,
              tradingInterests: response.data.errors.trading_interests,
              tradingOther: response.data.errors.trading_other,
              technologyInterests: response.data.errors.technology_interests,
              technologyOther: response.data.errors.technology_other,
              applicationsInterests: response.data.errors.applications_interests,
              applicationsOther: response.data.errors.applications_other,
              platformsInterests: response.data.errors.platforms_interests,
              platformsOther: response.data.errors.platforms_other,
              dataInterests: response.data.errors.data_interests,
              dataOther: response.data.errors.data_other,
              nftsInterests: response.data.errors.nfts_interests,
              nftsOther: response.data.errors.nfts_other,
            };
          } else {
            // update the Vuex state
            store.commit('setAuthentication', false);

            // Redirect the user to the login page
            router.push('/login');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error during registration:', error);
        }
      }
    };

    const validateForm = async () => {
      let isValid = true;

      ['username', 'email', 'password', 'confirmPassword', 'name'].forEach((field) => {
        if (!formData[field]) {
          formErrors.value[field] = [`${field} is required.`];
          isValid = false;
        } else {
          formErrors.value[field] = [];
        }
      });

      if (formData.password !== formData.confirmPassword) {
        formErrors.value.confirmPassword = ['Passwords do not match.'];
        isValid = false;
      }

      return isValid;
      };

    // Fetch CSRF token and session token if required
    onMounted(async () => {
      try {
        const { csrfToken, sessionToken } = await store.dispatch('retrieveTokens');
        // eslint-disable-next-line no-console
        console.log('CSRF Token:', csrfToken);
        // eslint-disable-next-line no-console
        console.log('Session Token:', sessionToken);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching tokens:', error);
      }
    });

    return {
      ...toRefs(formData),
      formErrors,
      register,
      isAuthenticated,
      investingChoices,
      tradingChoices,
      technologyChoices,
      applicationsChoices,
      platformsChoices,
      dataChoices,
      nftsChoices,
      csrfToken,
      sessionToken,
    };
  },
};
</script>


<style scoped>

.registration-form {
  max-width: 500px;
  min-width: 350px;
  margin: 0 auto;
}

.registration-form .form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 2px
}

.registration-form .form-group .checkbox-list {
  display: flex;
  flex-direction: column;
  margin: 3px;
}

.registration-form .form-group .checkbox-label {
  accent-color: #c63745;
  display: flex;
  align-items: left;
  padding: 3px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.registration-form .form-group .custom-checkbox {
  appearance: none;
  width: 30px;
  height: 30px;
  border: 3px solid #4caf50;
  background-color: white;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.registration-form .form-group .custom-checkbox:checked {
  background-color: #c63745 !important;
  border-color: #c63745 !important;
  box-shadow: 0 0 5px rgba(56, 77, 86, 0.6) !important;
}

.registration-form .form-group .checkbox-label:hover {
  color: #c63745;
  font-type: bold;
  transform: translateX(2px);
}

.registration-form .btn {
  background-color: #384d56;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.registration-form .btn:hover {
  background-color: #c63745;
}

.invalid-feedback {
  color: red;
}
</style>