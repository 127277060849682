<template>
  <div @click="onComponentClicked" v-if="!isLoading">
    <Vue3DraggableResizable
      class="search-card-container"
      :style="{ zIndex: getComponentZIndex }"
      :active="true"
      :resizable="true"
      :draggable="isGlobalDraggable"
      :x="300"
      :y="300"
      @activated="onComponentActivated"
      @closed="closeCard"
    >
    <div class="search-card-header">
      <span>Showing results for: "{{ lastQuestion }}"</span>
      <button class="close-button" @click="closeCard">X</button>
    </div>
    <div class="content">
      <div class="results-wrapper">
        <!-- LLM Results Section -->
        <div class="llm-result" v-if="llmResult && llmResult.text">
          <h3>LLM Answer</h3>
          <p>{{ llmResultText }}</p>
        </div>

        <!-- General Results Section -->
        <div class="search-result" v-if="generalResults.length">
          <div class="card-content">
            <div v-for="(result, index) in generalResults" :key="index" class="search-item">
              <h3>{{ result.title }}</h3>
              <p v-if="!isExpanded(index)">
                {{ result.description.substring(0, 150) }}...
                <button class="read-more" @click.prevent="toggleExpansion(index)">Read More</button>
              </p>
              <p v-else>
                {{ result.description }}
                <button class="show-less" @click.prevent="toggleExpansion(index)">Show Less</button>
              </p>
            </div>
          </div>
        </div>

        <!-- Search History Section -->
        <div class="search-history">
          <h3>Search History</h3>
          <ul>
            <li 
              v-for="(entry, index) in reversedQueryHistory" 
              :key="index" 
              @click="loadQueryResults(entry)" 
              class="search-history-item">
              {{ entry.query }}
            </li>
          </ul>
        </div>
      </div>

      <!-- Book Recommendations Section with Tooltips -->
      <div class="book-recommendations" v-if="bookResults.results.length">
          <h3 class="section-title">Recommended Books</h3>
          <div 
            v-for="(book, index) in bookResults.results"
            :key="index"
            class="book-item"
            @click="openBookCard(book)"
            @mouseover="handleMouseOver(book, $event)"
            @mouseleave="handleMouseLeave"
          >
            <a :href="book.url" target="_blank">
              <img v-if="book.cover_image" :src="getCoverImage(book.cover_image)" alt="Book Cover" class="book-cover" />
            </a>
            <!-- Tooltip -->
            <div v-if="tooltip.visible && tooltip.content.id === book.id" class="tooltip" :style="{ left: tooltip.position.x + 'px', top: tooltip.position.y + 'px' }">
            </div>
          </div>
        </div>
      </div>
    </Vue3DraggableResizable>
  </div>
</template>
  
  <script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import Vue3DraggableResizable from 'vue3-draggable-resizable';
  import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css';
  
  export default {
    props: {
      searchResult: Object,
      recommendedBooks: Array,
      initialX: Number,
      initialY: Number,
    },
    data() {
      return {
        selectedBookId: null,
        hideTooltipTimeout: null,
      };
    },
    computed: {
      ...mapState(['isGlobalDraggable']),
      ...mapState('search', ['lastQuestion', 'isLoading', 'selectedSearchId', 'tooltip', 'generalResults', 'bookResults', 'llmResult', 'isResultExpanded']),
      ...mapState('components', ['openedComponents']),
      getComponentZIndex() {
        const component = this.openedComponents.find(c => c.id === this.selectedSearchId)
        return component ? component.zIndex : 0;
      },
      queryHistory() {
        return this.$store.state.search.queryHistory;
      },
      reversedQueryHistory() {
        return [...this.queryHistory].reverse();
      },
      llmResultText() {
        if (this.llmResult) {
          return this.llmResult.text || ( this.llmResult.results.text);
        }
        return null;
      },
    },
    methods: {
      ...mapMutations('search', ['clearSelectedSearchId']),
      ...mapMutations('components', ['bringToFront', 'addComponent', 'removeComponent']),
      ...mapActions('search', ['toggleResultExpansion']),
      closeCard() {
        this.$store.commit('components/removeComponent', this.selectedSearchId);
        this.$store.commit('components/decrementCounter');
        this.clearSelectedSearchId();
      },
      openBookCard(book) {
        this.$emit('openBook', book); // Emit an event to open the book details card
      },
      selectSearchResult(id) {
        this.$store.commit('setSelectedSearchId', id);
      },
      getCoverImage(coverImage) {
        if (!coverImage) {
          return ''; // Return a default image path or an empty string
        }
        // Remove the 'books_covers' part of the path if it's included in coverImage
        const imagePath = coverImage.replace('/books_covers', '');
        return '/books_covers' + imagePath;
      },
      selectBook(bookId) {
        this.selectedBookId = bookId;
      },
      clearSelection() {
        this.selectedBookId = null;
      },
      onComponentClicked() {
        this.$store.commit('components/bringToFront', this.selectedSearchId);
      },
      onComponentActivated() {
        this.$store.commit('components/bringToFront', this.selectedSearchId);
      },
      handleMouseLeave() {
        // Start a delay before hiding the tooltip
        this.hideTooltipTimeout = setTimeout(() => {
          this.$store.dispatch('search/hideTooltip');
        }, 500); // Delay of 500ms
      },
      handleMouseOver(book, event) {
        // If there's a pending hide action, cancel it
        if (this.hideTooltipTimeout) {
          clearTimeout(this.hideTooltipTimeout);
          this.hideTooltipTimeout = null;
        }
        // Show the tooltip as before
        const position = { top: `${event.clientY + 10}px`, left: `${event.clientX + 10}px` };
        this.$store.dispatch('search/showTooltip', { content: book, position });
      },
      readMore(index) {
        this.toggleResultExpansion(index);
      },
      toggleExpansion(index) {
        this.$store.dispatch('search/toggleResultExpansion', index);
      },
      isExpanded(index) {
        return this.$store.getters['search/isResultExpanded'](index);
      },
      loadQueryResults(entry) {
        // Dispatch an action or commit mutation to update the store
        this.$store.dispatch('search/loadResultsFromHistory', entry);
      },
    },
    components: {
      Vue3DraggableResizable,
    },
    watch: {
      selectedSearchId(newId, oldId) {
        this.$store.commit('components/UPDATE_COMPONENT_ID', { oldId, newId });
      }
    },
    mounted() {
      if (this.selectedSearchId) {
        this.$store.commit('components/addComponent', {
          id: this.selectedSearchId,
          zIndex: this.$store.state.components.topZIndex,
        });
        this.$store.commit('components/incrementCounter');
        this.$store.commit('components/bringToFront', this.selectedSearchId);
      }
    },
  };
  </script>
  
  <style scoped>
.search-card-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 3px solid #384d56;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  height: 600px;
  width: 1000px;
}
.search-card-header {
  font-size: 20px;
  color: #fff;
  background-color: #384d56;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  cursor: move;
}
.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.results-wrapper {
  flex: 80%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
}
.book-recommendations {
  flex: 20%;
  overflow-y: auto;
  padding: 10px;
  border-left: 2px solid #eee;
}
.book-cover {
  width: 90%; /* Ensures the cover image scales correctly */
  height: auto;
  position: center;
}
.tooltip {
  position: fixed;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  white-space: nowrap;
}
.close-button {
  background-color: #c63745;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5%;
  cursor: pointer;
}
.llm-result {
  margin-bottom: 20px;
}
.search-history {
  padding: 10px;
  border-top: 2px solid #ccc;
}
.search-history-item {
  cursor: pointer;
  color: blue;
  margin: 5px 0;
}
.read-more {
  color: #c63745;
  cursor: pointer;
  font-size: larger;
  font-style: italic;
}
.show-less {
  color: #c63745;
  cursor: pointer;
  font-size: larger;
  font-style: italic;
}
</style>