// chartStore.js
import { instance } from './main';

export const state = {
  availableBaseCurrencies: [
    { name: 'Bitcoin', code: 'BTC' },
    { name: 'Ethereum', code: 'ETH' },
    { name: 'Cardano', code: 'ADA' },
    { name: 'Ripple', code: 'XRP' },
    { name: 'BNB Chain', code: 'BNB' },
    { name: 'Solana', code: 'SOL' },
    { name: 'Polkadot', code: 'DOT' },
    { name: 'Dogecoin', code: 'DOGE'},
    { name: 'Avalanche', code: 'AVAX' },
    { name: 'Shiba Inu', code: 'SHIB' },
    { name: 'Litecoin', code: 'LTC' },
    { name: 'Chainlink', code: 'LINK' },
    { name: 'Algorand', code: 'ALGO' },
    { name: 'TRON', code: 'TRX' },
    { name: 'NEAR Protocal', code: 'NEAR' },
    { name: 'Bitcoin Cash', code: 'bch' },
    { name: 'Polygon', code: 'MATIC' },
    { name: 'Stellar', code: 'XLM' },
    { name: 'Internet Computer', code: 'ICP' },
    { name: 'Uniswap', code: 'UNI' },
    { name: 'Dai', code: 'DAI' },
    { name: 'Hedera', code: 'HBAR' },
    { name: 'Cronos', code: 'CRO' },
    { name: 'VeChain', code: 'VET' },
    { name: 'Filecoin', code: 'FIL' },
    { name: 'Ethereum Classic', code: 'ETC' },
    { name: 'Cosmos', code: 'ATOM' },

  ],
  availableQuoteCurrencies: [
    { name: 'Tether', code: 'USDT' },
    { name: 'US dollar', code: 'USD' },
    { name: 'US dollar coin', code: 'USDC' },
    { name: 'Bitcoin', code: 'BTC' },
    { name: 'Ethereum', code: 'ETH' },
  ],
  selectedBaseCurrency: {},
  selectedQuoteCurrency: {},
  selectedTimeframe: '',
  charts: {}, // to store multiple charts
  selectedChartId: null,
};

export const getters = {
  getAvailableBaseCurrencies: (state) => state.availableBaseCurrencies,
  getAvailableQuoteCurrencies: (state) => state.availableQuoteCurrencies,
  getSelectedBaseCurrency: (state) => state.selectedBaseCurrency,
  getSelectedQuoteCurrency: (state) => state.selectedQuoteCurrency,
  getChartById: (state) => (chartId) => {
    return state.charts[chartId]?.data;
  },
};

export const mutations = {
  SET_SELECTED_BASE_CURRENCY: (state, { name, code }) => {
    console.log('Setting selected base currency:', { name, code });
    state.selectedBaseCurrency = { name, code };
  },
  
  SET_SELECTED_QUOTE_CURRENCY: (state, { name, code }) => {
    console.log('Setting selected quote currency:', { name, code });
    state.selectedQuoteCurrency = { name, code };
  },
  SET_SELECTED_TIMEFRAME: (state, timeframe) => {
    console.log('Setting selected timeframe:', timeframe);
    state.selectedTimeframe = timeframe;
  },
  SET_CHART_DATA(state, { chartId, data, quoteCurrency, baseCurrency, timeframe }) {
    // store chart data in state
    state.charts[chartId] = {
      data: data,
      quoteCurrency: quoteCurrency,
      baseCurrency: baseCurrency,
      timeframe: timeframe
    };
  },
  CLEAR_CHART_DATA(state, chartId) {
    delete state.charts[chartId]; // Clear the specific chart data
  },
  SET_SELECTED_CHART_ID(state, chartId) {
    state.selectedChartId = chartId;
  },
};

export const actions = {
  async fetchChartData({ commit }, { chartId, baseCurrency, quoteCurrency, timeframe }) {
    try {
      const response = await instance.get('/api/chart-prices', {
        params: { 
          base_currency: baseCurrency.toLowerCase(), 
          quote_currency: quoteCurrency.toLowerCase(),
          timeframe: timeframe.toLowerCase()
        }
      });
        console.log("Response data: ", response.data); // Ensure data is fetched
        if (Array.isArray(response.data)) {
          commit('SET_CHART_DATA', { 
            chartId: chartId,
            data: response.data,
            quoteCurrency: quoteCurrency,
            baseCurrency: baseCurrency,
            timeframe: timeframe});
        } else {
          console.error('Unexpected data format:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch chart data:', error);
    }
  },
  updateSelectedChartId({ commit }, chartId) {
    commit('SET_SELECTED_CHART_ID', chartId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};