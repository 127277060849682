<template>
  <div @click="onComponentClicked" v-if="isChartDataAvailable && isChartAllowed">
    <Vue3DraggableResizable class="draggable"
      ref="draggableContainer"
      :style="{ zIndex: getComponentZIndex }"
      :draggable="isGlobalDraggable"
      :resizable="true"
      :dragHandle="'.drag-handle'"
      :w="initialWidth"
      :h="initialHeight"
      :x="400"
      :y="300"
      @resizing="adjustChartSize">

      <button class="close-button" 
        @click="closeChart"
        :style="{ zIndex: closeButtonZIndex }"
        >X</button>
        
      <div class="chart-container" ref="chartContainer" :id="localChartId + '_chart-container'">
        <ejs-stockchart
          ref="chart"
          :id="localChartId + '_chart'"
          :primaryXAxis="primaryXAxis"
          :primaryYAxis="primaryYAxis"
          :crosshair="crosshair"
          :tooltip="tooltip"
          :title="dynamicTitle"
          :theme="theme"
          :width="chartWidth"
          :height="chartHeight">
          <e-stockchart-series-collection>
            <e-stockchart-series 
              :dataSource="tradingPairData"
              type="Candle"
              volume='volume'
              xName='date'
              low='low'
              high='high'
              open='open'
              close='close'>
            </e-stockchart-series>
          </e-stockchart-series-collection>
        </ejs-stockchart>
      </div>
    </Vue3DraggableResizable>
  </div>
</template>

<script>
import Vue3DraggableResizable from 'vue3-draggable-resizable';
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css';
import { mapGetters, mapState } from 'vuex';
import { StockChartComponent, LineSeries, SplineSeries, StockChartSeriesCollectionDirective, StockChartSeriesDirective,
  HiloOpenCloseSeries, HiloSeries, DateTime, Logarithmic, CandleSeries, Crosshair, Tooltip, RangeTooltip, RangeAreaSeries,
  Trendlines, EmaIndicator, RsiIndicator, BollingerBands, TmaIndicator, MomentumIndicator, SmaIndicator, AtrIndicator, 
  AccumulationDistributionIndicator, MacdIndicator, StochasticIndicator } from "@syncfusion/ej2-vue-charts";

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Bootstrap-dark";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i,  'Contrast');

function generateUniqueChartId(chartId) {
  return `${chartId}-${Math.random().toString(36).substring(2, 11)}`;
}

export default {
  name: "TradingPairChart",
  props: {
    chartId: {
      type: String,
      required: true,
    },
  },
  emits: ['closeChart'],
  
  components: {
    Vue3DraggableResizable,
    "ejs-stockchart": StockChartComponent,
    "e-stockchart-series-collection": StockChartSeriesCollectionDirective,
    "e-stockchart-series": StockChartSeriesDirective,
  },

  data() {
    return {
      localChartId: generateUniqueChartId(this.chartId), // Generate localChartId here
      observer: null, // MutationObserver instance
      popupOpen: false,
      theme: theme,
      initialWidth: 700,
      initialHeight: 500,
      enableAutoResizing: true,
      canResize: true,
      resizeDebounce: null,
      chartWidth: '100%',
      chartHeight: '100%',
      retryCount: 0,
      maxRetries: 5,
      
      // Initializing Primary X Axis
      primaryXAxis: {
        valueType: "DateTime",
        majorGridLines: { color: "transparent", width: 1 },
        majorTickLines: { color: "transparent", width: 0 },
        crosshairTooltip: { enable: true },
        labelformat: "hm"
      },

      // Initializing Primary Y Axis
      primaryYAxis: {
        lineStyle: { color: "white" },
        majorTickLines: { color: "white", width: 1 },
        majorGridLines: { color: "white", width: 1 },
      },
      crosshair: { enable: true, lineType: "Vertical"},
      tooltip: { enable: true, shared: true },
      chartArea: {
        border: { width: 1, color: "white" }
      },
      seriesType: [],
      trendlineType: [],
      exportType: [],
    };
  },
  
  computed: {
    ...mapGetters('charts', ['getChartById']),
    ...mapState('charts', ['selectedChartId', 'selectedBaseCurrency', 'selectedQuoteCurrency', 'selectedTimeframe']),
    ...mapState(['isGlobalDraggable']),
    ...mapState('components', ['openedComponents', 'componentCounter', 'topZIndex']),
    ...mapState({
      chartData: function(state) {
        console.log(state.charts.charts, this.localChartId);
        return state.charts.charts[this.localChartId];
      }
    }),
    
    dynamicTitle() {
      if (this.chartData) {
        const baseCurrency = this.chartData.baseCurrency;
        const quoteCurrency = this.chartData.quoteCurrency;
        const timeFrame = this.chartData.timeframe;

        return `${baseCurrency} / ${quoteCurrency} - ${timeFrame}`;
      }
      return "Trading Pair Price Analysis";
    },
    tradingPairData() {
      if (this.chartData) {
        const candlesArray = this.chartData.data;
        console.log('Chart data:', candlesArray);
        return candlesArray ? candlesArray.map(item => ({
          date: new Date(item.date), // Ensure date is in the correct format
          open: item.open,
          high: item.high,
          low: item.low,
          close: item.close,
          volume: item.volume
        })) : [];
      }
      return [];
    },
    isChartDataAvailable() {
      return this.tradingPairData && this.tradingPairData.length > 0;
    },
    getComponentZIndex() {
      const component = this.openedComponents.find(c => c.id === this.localChartId);
      return component ? component.zIndex : 0;
    },
    closeButtonZIndex() {
      return this.getComponentZIndex + 1; // Ensure close button is on top
    },
    isChartAllowed() {
      const maxComponents = this.$store.getters['components/maxComponents'];
      const currentOpenedComponents = this.$store.state.components.openedComponents.length;
      const isCurrentlyOpened = this.$store.state.components.openedComponents.some(c => c.id === this.localChartId);

      // Allow if the component is already opened or if adding a new one doesn't exceed the limit
      return isCurrentlyOpened || currentOpenedComponents < maxComponents;
    },
  },

  watch: {
    topZIndex(newZIndex, oldZIndex) {
      if (newZIndex !== oldZIndex) {
        this.adjustPopupPosition();
      }
    },
    '$refs.chartContainer': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.adjustPopupPosition();
        }
      },
      deep: true,
    },
    popupOpen() {
      this.$nextTick(() => {
        this.adjustPopupPosition();
      });
    },
    chartId(newVal) {
      if (!newVal) {
        console.error('chartId is not defined');
      }
    },
  },

  methods: {
    async addComponent() {
      console.log('Checking if component is allowed:', this.isChartAllowed);
      if (!this.isChartAllowed) {
        console.log('Component limit reached, cannot add more components.');
        return; // Exit early if not allowed to add more components
      }
      const component = {
        id: this.localChartId,
        zIndex: this.$store.state.components.topZIndex,
        type: 'chart',
      };

      // Check if component already exists in the state
      const existingComponent = this.$store.state.components.openedComponents.find(c => c.id === component.id);
      if (!existingComponent) {
        const canAddComponent = await this.$store.dispatch('components/addComponentIfAllowed', component);
        if (canAddComponent) {
          console.log('Component added successfully:', component);
          this.$store.commit('components/addComponent', component);
          this.$store.commit('components/bringToFront', this.localChartId);
          this.$store.commit('components/incrementCounter');
        } else {
          console.warn('Component addition prevented:', component);
        }
      } else {
        console.warn(`Component ${component.id} already exists in the state.`);
      }
    },
    removeComponent() {
      console.log('Removing component:', this.localChartId);
      this.$store.commit('components/removeComponent', this.localChartId);
      this.$store.commit('components/decrementCounter');
    },
    refreshChart() {
      if (this.$refs.chart && this.$refs.chart.ej2Instances) {
        this.$refs.chart.ej2Instances.refresh();
      }
    },
    adjustChartSize() {
      console.log('adjustChartSize called', this.$refs.chartContainer);
      if (this.$refs.chartContainer) {
        this.chartWidth = `${this.$refs.chartContainer.clientWidth}px`;
        this.chartHeight = `${this.$refs.chartContainer.clientHeight}px`;
        this.refreshChart();
      }
    },
    forceResize() {
      // Trigger a resize event
      window.dispatchEvent(new Event('resize'));
    },
    onComponentClicked() {
      this.$store.commit('components/bringToFront', this.localChartId);
    },
    closeChart() {
      console.log('Closing chart:', this.localChartId);
      this.$emit('closeChart', this.localChartId); // Emit an event to notify the parent
    },
    handleIndicatorPopup() {
      const popups = document.querySelectorAll('.e-dropdown-popup.e-popup-open');
      popups.forEach(popup => {
        const chartId = popup.id.split('_')[0];
        if (chartId === this.localChartId) {
          popup.id = `${this.localChartId}_indicatorType-popup`;
          this.adjustPopupPosition();
        }
      });
    },
    adjustPopupPosition() {
      console.log('adjustPopupPosition called', this.$refs.chartContainer);
      const chartContainer = this.$refs.chartContainer;
      if (!chartContainer) {
        console.log('chartContainer is null');
        return;
      }
      const popup = document.querySelector(`#${this.localChartId}_indicatorType-popup.e-dropdown-popup.e-lib.e-popup.e-control.e-popup-open`);
      if (popup) {
        console.log('popup:', popup);
        const containerRect = chartContainer.getBoundingClientRect();
        popup.style.position = 'fixed'; // Change this to 'fixed'
        let newTop = containerRect.top + window.scrollY + 70; // Adjust this value as needed
        if (newTop < 0) {
          newTop = 0;
        }
        popup.style.top = `${newTop}px`;
        popup.style.left = `${containerRect.left + window.scrollX + 30}px`; // Adjust this value as needed
        this.applyStylesToPopup(popup); // Call to apply styles to the popup
        console.log('Popup repositioned:', popup.style.top, popup.style.left); // Debugging output
        this.retryCount = 0; // Reset retry count on success
      } else {
        console.log(`popup for ${this.localChartId} not found`);
        if (this.retryCount < this.maxRetries) {
          this.retryCount++;
          setTimeout(() => {
            this.adjustPopupPosition();
          }, 500);
        } else {
          console.log(`Reached max retries for ${this.localChartId}`);
          this.retryCount = 0; // Reset retry count after reaching max retries
        }
      }
    },
    applyStylesToPopup(popup) {
      // Apply background color and text color
      popup.style.zIndex = `${this.topZIndex + 1}`;
      popup.style.backgroundColor = '#384d56'; // Dark background for better visibility
      popup.style.color = '#fff'; // White text color for contrast
      popup.style.cursor = 'pointer'; // Pointer cursor on hover
      // Ensure all list items inside the popup have consistent styling
      const listItems = popup.querySelectorAll('li');
      listItems.forEach(li => {
        li.style.backgroundColor = '#384d56'; // Dark background for list items
        li.style.color = '#fff'; // White text color for list items
        li.style.cursor = 'pointer'; // Pointer cursor on hover
      });
    },
    saveIndicatorsState() {
      const indicators = [];
      const chart = this.$refs.chart?.ej2Instances;
      if (chart) {
        chart.indicators.forEach(indicator => {
          indicators.push({
            type: indicator.type,
            properties: {
              ...indicator.properties,
            }
          });
        });
        try {
          const compressedState = this.compressData(indicators);
          localStorage.setItem(`${this.localChartId}_indicatorsState`, compressedState);
        } catch (error) {
          console.error('Failed to save indicators state:', error);
        }
      }
    },
    compressData(data) {
      return JSON.stringify(data, this.circularReplacer()).substring(0, 5120); // Limit to 5KB
    },
    circularReplacer() {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    },
    loadIndicatorsState() {
      const savedState = localStorage.getItem(`${this.localChartId}_indicatorsState`);
      if (savedState) {
        const indicators = this.decompressData(savedState);
        const chart = this.$refs.chart?.ej2Instances;
        if (chart) {
          indicators.forEach(indicator => {
            chart.indicators.push(new indicator.type(indicator.properties));
          });
          chart.refresh();
        }
      }
    },
    decompressData(data) {
      return JSON.parse(data);
    },
  },

  provide: {
    stockChart: 
    [ LineSeries, SplineSeries, DateTime, Crosshair, Logarithmic, Tooltip, RangeTooltip, CandleSeries,
      RangeAreaSeries, Trendlines, EmaIndicator, RsiIndicator, BollingerBands, TmaIndicator, MomentumIndicator,SmaIndicator, 
      AtrIndicator, AccumulationDistributionIndicator, MacdIndicator, StochasticIndicator, HiloOpenCloseSeries, HiloSeries ]
  },

  async mounted() {
    this.$nextTick(() => {
      this.adjustChartSize();
      window.addEventListener('resize', this.adjustChartSize);
      setTimeout(this.forceResize, 50);

      if (this.$refs.chart) {
        const chartInstance = this.$refs.chart.ej2Instances;
        chartInstance.toolbarClick = (args) => {
          if (args.item.id.indexOf('_indicatorType') !== -1) {
            setTimeout(() => {
              this.handleIndicatorPopup();
            }, 300); // Give some time for the popup to render
          }
        };
        this.loadIndicatorsState();
      }
    });

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('e-dropdown-popup')) {
              const chartId = node.id.split('_')[0];
              if (chartId === this.localChartId) {
                node.id = `${this.localChartId}_indicatorType-popup`;
                console.log(`New popup added with ID: ${node.id}`);
                this.observer.observe(node, { attributes: true, attributeFilter: ['style'], childList: false, subtree: false });
              }
            }
          });
        } else if (mutation.attributeName === "style") {
          const target = mutation.target;
          if (target.nodeType === Node.ELEMENT_NODE && target.classList.contains('e-popup-open')) {
            if (target.id === `${this.localChartId}_indicatorType-popup`) {
              this.adjustPopupPosition();
            }
          }
        }
      });
    });
    this.observer.observe(document, { childList: true, subtree: true });
  },

  beforeUnmount() {
    console.log('Before Unmount: ', this.localChartId);
    window.removeEventListener('resize', this.adjustChartSize);
    if (this.observer) {
      this.observer.disconnect(); // Cleanup to prevent memory leaks
    }
    if (this.$refs.chart) {
      this.saveIndicatorsState();
    }
    this.$store.commit('components/removeComponent', this.localChartId);
  },
};
</script>

<style scoped>

.draggable {
  background-color: #384d56;
  color: #fff;
  cursor: move;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  user-select: none;
}
.chart-container {
  width: 100%; /* Ensure the chart container takes up the full width */
  height: 100%; /* Ensure the chart container takes up the full height */
  background-color: #384d56;
  user-select: none;
  border: 3px solid #384d56;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: relative;
}
.close-button {
  position: absolute;
  top: 7px;
  right: 7px;
  background-color: #c63745;
  color: #fff;
  border: none;
  padding: 10px 10px;
  border-radius: 15%;
  cursor: pointer;
}
:deep(#crypto-chart-container_indicatorType) {
  color: #fff !important;
  font-weight: bold !important;
}
:deep(.e-tbar-btn.e-tbtn-txt.e-control.e-btn.e-lib.e-flat.e-active) {
  color: #fff !important;
  font-weight: bold !important;
}

</style>
