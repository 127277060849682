// store/searchStore.js
import { instance } from './main';

const state = {
    userQuestions: [],
    lastQuestion: '',
    bookResults: [],
    llmResult: null,
    generalResults: [],
    isLoading: false,
    tooltip: {
        visible: false,
        content: {},
    },
    selectedSearchId: null,
    error: null,
    searchInstances: new Map(),
    cursorOnTooltip: false,
    expandedResults: {}, // Tracks which search results are expanded
    queryHistory: [],
    searchOptions: {
        selectedIndexes: {
          all: true,
          wpSummaries: false,
          generalInfo: false,
        },
        useLLM: false,
      },
};

const getters = {
    bookResults: state => state.bookResults,
    generalResults: state => state.generalResults,
    isLoading: state => state.isLoading,
    error: state => state.error,
    selectedSearchId: state => state.selectedSearchId,
    isResultExpanded: (state) => (index) => {
        // Check if the result at the given index is expanded
        return !!state.expandedResults[index];
      },
};

const mutations = {
    addQuestion(state, question) {
        state.userQuestions.push(question);
        state.lastQuestion = question;
    },
    addSearchInstance(state, { id, data }) {
        state.searchInstances.set(id, data);
    },
    removeSearchInstance(state, id) {
        state.searchInstances.delete(id);
    },
    setBookResults(state, { query, results }) {
        state.bookResults = {
            query,
            results,
            timestamp: new Date(),
        };
    },
    setGeneralResults(state, { query, results }) {
        state.generalResults = results.map(result => ({
            ...result,
            query,
            timestamp: new Date(),
            isExpanded: false,
            readMoreIndex: 0,
        }));
    },
    TOGGLE_READ_MORE(state, resultIndex) {
        const result = state.generalResults[resultIndex];
        result.isExpanded = !result.isExpanded;
        // Optionally increment readMoreIndex or handle it as per your requirements
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
    showTooltip(state, { content, position }) {
        state.tooltip = {
            visible: true,
            content,
            position
        };
    },
    hideTooltip(state) {
        state.tooltip.visible = false;
    },
    setError(state, error) {
        state.error = error;
    },
    setSelectedSearchId(state, id) {
        state.selectedSearchId = id;
    },
    clearSelectedSearchId(state) {
        state.selectedSearchId = null;
    },
    SET_CURSOR_ON_TOOLTIP(state, value) {
        state.cursorOnTooltip = value;
    },
    SET_RESULT_EXPANSION(state, { index, expanded }) {
        if (expanded) {
          // Mark the result as expanded
          state.expandedResults = { ...state.expandedResults, [index]: true };
        } else {
          // Remove the result from the expanded list
          const newExpandedResults = { ...state.expandedResults };
          delete newExpandedResults[index];
          state.expandedResults = newExpandedResults;
        }
      },
    UPDATE_SEARCH_OPTIONS(state, options) {
      state.searchOptions = options;
    },
    ADD_QUERY_TO_HISTORY(state, queryObject) {
        // Append the new query object to the history array
        state.queryHistory.push(queryObject);
    
        // If the history exceeds 10 objects, remove the oldest (first in the array)
        if (state.queryHistory.length > 10) {
          state.queryHistory.shift(); // Remove the first element
        }
    },
    SET_CURRENT_RESULTS(state, results) {
        state.currentResults = results; // Ensure 'currentResults' is defined in your state
    },
    SET_BOOK_RESULTS(state, payload) {
        state.bookResults = payload.bookResults || { results: [] };
    },
    SET_GENERAL_RESULTS(state, payload) {
        state.generalResults = payload.generalResults || [];
    },
    SET_LLM_RESULT(state, llmResult) {
        state.llmResult = llmResult;
    },
};

const actions = {
    performSearch({ commit, rootState }, payload) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            instance.post('/api/search', payload, {  // Using the entire payload
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': rootState.csrfToken,
                },
            })
            .then(response => {
                const firstGeneralResultId = response.data.generalResults.length > 0 ? response.data.generalResults[0].id : null;
                const firstBookResultId = response.data.bookResults.length > 0 ? response.data.bookResults[0].id : null;
                const firstLLMResultId = response.data.llmResult.length > 0 ? response.data.llmResult[0].id : null;
                const selectedSearchId = firstLLMResultId || firstGeneralResultId || firstBookResultId;
                commit('setSelectedSearchId', selectedSearchId);
    
                commit('setBookResults', { query: payload.query, results: response.data.bookResults });
                commit('setGeneralResults', { query: payload.query, results: response.data.generalResults });
                commit('SET_LLM_RESULT', response.data.llmResult || []);
                commit('addQuestion', payload.query);
        
                const queryObject = {
                    query: payload.query,
                    results: {
                        bookResults: response.data.bookResults,
                        generalResults: response.data.generalResults,
                        llmResult: response.data.llmResult,
                    },
                    selectedSearchId: selectedSearchId,
                };
                commit('ADD_QUERY_TO_HISTORY', queryObject);
    
                resolve();
            })
            .catch(error => {
                commit('setError', error.toString());
                reject(error);
            })
            .finally(() => {
                commit('setLoading', false);
            });
        });
    },
    setSelectedSearchId({ commit }, id) {
        commit('setSelectedSearchId', id);
    },
    clearSelectedSearchId({ commit }) {
        commit('clearSelectedSearchId');
    },
    showTooltip({ commit }, { content, position }) {
        commit('showTooltip', { content, position });
    },
    hideTooltip({ commit, state }) {
        if (!state.cursorOnTooltip) {
          commit('hideTooltip');
        }
      },
    setCursorOnTooltip({ commit }, value) {
        commit('SET_CURSOR_ON_TOOLTIP', value);
    },
    toggleReadMore({ commit, state }, resultIndex) {
        if (resultIndex < state.generalResults.length) {
            commit('TOGGLE_READ_MORE', resultIndex);
        }
    },
    toggleResultExpansion({ commit, getters }, index) {
        // Toggle the expansion state for the result at the given index
        const isCurrentlyExpanded = getters.isResultExpanded(index);
        commit('SET_RESULT_EXPANSION', { index, expanded: !isCurrentlyExpanded });
    },
    setSearchOptions({ commit }, options) {
        commit('UPDATE_SEARCH_OPTIONS', options);
    },
    addToQueryHistory({ commit }, query) {
        commit('ADD_QUERY_TO_HISTORY', query);
    },
    loadResultsFromHistory({ commit }, entry) {
        commit('setBookResults', { results: entry.results.bookResults });
        commit('setGeneralResults', { results: entry.results.generalResults });
        commit('SET_LLM_RESULT', entry.results.llmResult );
        // Optionally, also update lastQuestion to reflect the history item's query
        commit('addQuestion', entry.query);
      },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};