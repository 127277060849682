import { createStore } from 'vuex';
import { instance } from './main';
import railModule from './railModule';
import newsModule from './newsStore';
import settingsModule from './settingsStore';
import chartsModule from './chartStore';
import draggableComponentStore from './draggableComponentStore';
import searchModule from './searchStore';
import userStore from './userStore';

const store = createStore({
  modules: {
      rail: railModule,
      news: newsModule,
      settings: settingsModule,
      charts: chartsModule,
      search: searchModule,
      components: draggableComponentStore,
      users: userStore,
      },
  state: {
    isAuthenticated: false,
    flashMessages: [],
    csrfToken: '',
    sessionToken: '',
    isEmailVerified: false,
    openedComponents: [],
    isGlobalDraggable: true,
  },
  getters: {
    flashMessages: (state) => state.flashMessages,
    isAuthenticated: (state) => state.isAuthenticated,
    isEmailVerified: (state) => state.isEmailVerified,
    csrfToken: (state) => {
      // eslint-disable-next-line no-console
      console.log('Getting CSRF token:', state.csrfToken);
      return state.csrfToken;
    },
    sessionToken: (state) => {
      // eslint-disable-next-line no-console
      console.log('Getting session token:', state.sessionToken);
      return state.sessionToken;
    },
  },
  mutations: {
    setAuthentication(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setFlashMessages(state, messages) {
      state.flashMessages = messages;
    },
    setEmailVerifiedStatus(state, status) {
      state.isEmailVerified = status;
    },
    addFlashMessage(state, message) {
      state.flashMessages = [...state.flashMessages, message];
    },
    removeFlashMessage(state, messageIndex) {
      state.flashMessages = [
        ...state.flashMessages.slice(0, messageIndex),
        ...state.flashMessages.slice(messageIndex + 1),
      ];
    },
    updateCsrfToken(state, csrfToken) {
      state.csrfToken = csrfToken;
    },
    updateSessionToken(state, sessionToken) {
      state.sessionToken = sessionToken;
    },
    // Add an opened component state
    ADD_OPENED_COMPONENT(state, componentState) {
      state.openedComponents.push(componentState);
    },
    // Remove a closed component state
    REMOVE_OPENED_COMPONENT(state, componentState) {
    state.openedComponents = state.openedComponents.filter(
      (item) => item.id !== componentState.id
    );
    },
    TOGGLE_GLOBAL_DRAGGABLE(state) {
      state.isGlobalDraggable = !state.isGlobalDraggable;
    },
  },

  actions: {
    async retrieveTokens({ commit }) {
      try {
        const response = await instance.get('/api/tokens');
        const { csrfToken, sessionToken } = response.data;
        commit('updateCsrfToken', csrfToken);
        commit('updateSessionToken', sessionToken);
        return { csrfToken, sessionToken };
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error retrieving tokens:', error);
        return Promise.reject(error);
      }
    },
    async verifyEmailToken({ commit }) {
      try {
        const response = await instance.get('/api/verification');
        // eslint-disable-next-line no-console
        console.log('email verified: ', response.data);
        const isVerified = response.data.isVerified
        commit('setEmailVerifiedStatus', isVerified);
        //success message
      } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error confirming email verification status:', error)
      return Promise.reject(error);
    }
    },
    toggleGlobalDraggable({ commit }) {
      commit('TOGGLE_GLOBAL_DRAGGABLE');
    },
    async loadMoreNewsTitles({ dispatch }) {
      await dispatch('news/loadMoreNewsTitles'); // Call the action in NewsStore
    },
    async logout({ commit, state }) {
      try {
        const csrfToken = state.csrfToken;

        // Make the POST request to the logout API endpoint
        const response = await instance.post('/api/logout', {}, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken, // Include the CSRF token in the headers
          },
        });

        console.log(response.data);

        // If the logout was successful, update the authentication state
        commit('setAuthentication', false);
      } catch (error) {
        console.error('Logout error:', error);
      }
    },
  },
});

export default store;