<!--
-----------------------------------------------------------------------------------------
This component represents the main dashboard layout for the user.
-----------------------------------------------------------------------------------------
Components:
- RailBar: Navigation rail bar component.
- NewsTitleList: Displays a list of news article titles.
- NewsCard: Displays a detailed view of a news article.

Data:
- selectedNewsItem: Object representing the selected news article.
- showNewsSubItems: Boolean indicating whether news sub-items are shown.

Methods:
- openNewsCard: Displays the detailed view of a news article.
- closeNewsCard: Closes the detailed view of a news article.

Usage:
This component serves as the primary dashboard layout for the user. It includes a RailBar for navigation, which allows users to switch between different sections of the dashboard. It also includes a NewsTitleList component that displays a list of news article titles for quick overview. The NewsCard component is used to display detailed information about a selected news article. Users can open and close the NewsCard component by interacting with the NewsTitleList component.
-->


<template>
  <div class="dashboard-container">
    <div class="widgets">
      <RailBar />
      <button @click="toggleDraggable">
        Dragging <br> {{ isGlobalDraggable ? 'Enabled' : 'Disabled' }}
      </button>
      <div class="drag-toggle">
        <label class="switch">
          <input type="checkbox" v-model="isGlobalDraggable" @change="toggleDraggable">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <NewsTitleList
      v-for="keyword in Object.keys(newsFeed)"
      :key="keyword"
      :keyword="keyword"
      @select="updateKeyword"
    /> 
    
    <NewsCard 
      v-for="keyword in filteredNews"
      :key="keyword"
      :keyword="keyword"
      @close="closeNewsCard"
    />

    <ChartsCandleChart 
      v-for="chartId in openedCharts"
      :key="chartId"
      :chartId="chartId"
      @closeChart="closeChart"
    />

    <SearchCard 
      v-if="selectedSearchId" 
      :searchId="selectedSearchId" 
      :onClose="closeSearch"
      @showTooltip="handleTooltipShow"
      @hideTooltip="hideTooltip"
    />

    <BookTooltip
      v-if="tooltip.visible"
      :book="$store.state.search.tooltip.content"
      :style="tooltipStyle"
      @mouseOverTooltip="cancelTooltipHide"
      @mouseLeaveTooltip="scheduleTooltipHide"
    />

    <div class="navigation-links">
      <!-- Navigation Links go here -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import RailBar from './RailBar.vue';
import NewsTitleList from './NewsTitleList.vue';
import NewsCard from './NewsCard.vue';
import ChartsCandleChart from './ChartsCandleChart.vue';
import SearchCard from './SearchCard.vue';
import BookTooltip from './BookTooltip.vue';
import { EventBus } from '../../EventBus.js';

export default {
  components: {
    RailBar,
    NewsTitleList,
    NewsCard,
    ChartsCandleChart,
    SearchCard,
    BookTooltip,
  },

  computed: {
    ...mapState(['isGlobalDraggable']),
    ...mapState('news', ['selectedNews', 'newsFeedGenerated', 'newsFeed', 'newsCardGenerated', 'currentKeywords']),
    ...mapState('settings', ['isSettingsDisplayCardOpen']),
    ...mapState('charts', ['selectedChartId']),
    ...mapState('search', ['selectedSearchId', 'tooltip']),
    ...mapState('components', ['openedComponents', 'componentCounter']),
    ...mapState('users', ['maxComponents']),
    selectedNewsItem(){
      const selectedNewsItem = this.$store.state.news.selectedNews[this.keyword];
      return selectedNewsItem;
    },
    keyword() {
      return this.$store.state.news.currentKeywords;
    },
    filteredNews() {
      if (!this.selectedNewsItem) {
        return [];
      }
      return Object.keys(this.selectedNews);
    },
    openedCharts() {
      console.log('Opened Charts:', this.openedComponents.map(component => component.id));
      return this.openedComponents.map(component => component.id);
    }
  },

  data() {
    return {
      initialWidth: 800, // Set an initial width (adjust as needed)
      initialHeight: 500, // Set an initial height (adjust as needed)
      tooltipVisible: false,
      tooltipBook: null,
      tooltipStyle: {},
      hideTooltipTimeout: null,
      currentKeyword: null,
    };
  },

  methods: {
    ...mapActions(['toggleGlobalDraggable']),
    ...mapActions('components', ['addComponentIfAllowed']),
    ...mapActions('users', ['fetchUserInfo']),
    closeNewsCard(keyword) {
      this.$store.commit('news/CLEAR_SELECTED_NEWS', keyword);
    },
    closeSettingsDisplayCard() {
      this.$store.commit('settings/CLOSE_SETTINGS_CARD');
    },
    closeChart(chartId) {
      console.log('Closing chart from UserDashboard:', chartId);
      this.$store.commit('charts/CLEAR_CHART_DATA', chartId);
      this.$store.commit('components/removeComponent', chartId);
      this.$store.commit('components/decrementCounter');
    },
    closeSearch() {
      this.$store.dispatch('search/clearSelectedSearchId');
    },
    toggleDraggable() {
      this.$store.commit('TOGGLE_GLOBAL_DRAGGABLE');
    },
    handleTooltipShow({ book, x, y }) {
      clearTimeout(this.hideTooltipTimeout);
      this.$store.dispatch('search/showTooltip', { content: book, position: { x, y } });
    },
    hideTooltip() {
       // Use a Vuex getter or direct state access to check cursorOnTooltip
      if (!this.$store.state.search.cursorOnTooltip) {
        this.$store.dispatch('search/hideTooltip');
      }
    },
    handleTooltipMouseOver() {
      if (this.hideTooltipTimeout) {
        clearTimeout(this.hideTooltipTimeout);
        this.hideTooltipTimeout = null;
      }
    },
    handleTooltipMouseLeave() {
      if (!this.$store.state.search.cursorOnTooltip) {
        this.hideTooltipTimeout = setTimeout(() => {
            this.$store.dispatch('search/hideTooltip');
        }, 500); // Adjust the timeout as needed
      }
    },
    cancelTooltipHide() {
      clearTimeout(this.hideTooltipTimeout);
    },
    scheduleTooltipHide() {
      this.hideTooltipTimeout = setTimeout(() => {
        this.$store.dispatch('search/hideTooltip');
      }, 500); // Adjust the timeout as needed
    },
    beforeDestroy() {
      clearTimeout(this.hideTooltipTimeout);
    },
    selectNewsItem(keyword) {
      this.$store.dispatch('news/setCurrentKeyword', keyword);
      this.$store.dispatch('news/setSelectedNews', { keyword, newsItem: this.newsFeed[keyword] });
    },
    updateKeyword(keyword) {
      this.currentKeyword = keyword;
    },
    async requestNewComponent(component) {
      // Check if the component counter is within the limit
      if (this.componentCounter < this.maxComponents) {
        console.log(`Available new components: ${this.maxComponents - this.componentCounter}`);

        // Dispatch to add component if allowed
        const canAddComponent = await this.$store.dispatch('components/addComponentIfAllowed', component);

        // Log result based on whether the component was added or not
        if (canAddComponent) {
          console.log('Component added successfully:', component);
        } else {
          console.warn('Component addition prevented:', component);
        }
      } else {
        alert(`You cannot open more than ${this.maxComponents} components.`);
      }
    }
  },

  created() {
    // Fetch news data for each keyword when the component is created
    Object.keys(this.newsFeed).forEach(keyword => {
      this.$store.dispatch('news/updateKeywordAndFetchNews', keyword);
    });
    // Listen for new component requests
    console.log('Listening for new component requests');
    EventBus.on('request-new-component', this.requestNewComponent);
  },

  beforeUnmount() {
    console.log('Removing event listener - component management')
    // Remove event listener before the component is destroyed
    EventBus.off('request-new-component', this.requestNewComponent);
  }
};
</script>

<style scoped>
/* Add component styles here */
.dashboard-container {
  display: flex;
  flex-direction: row;
  height: 80vh;
}

.widgets {
  flex: 85%; /* Adjust as needed */
}

.drag-toggle {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.drag-toggle p {
  margin-right: 10px;
  font-size: 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c63745;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #a8b2b6;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #384d56;
}

input:focus + .slider {
  box-shadow: 0 0 1px #384d56;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.option-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.switch.smaller {
  width: 36px; /* 60% of the original 60px width */
  height: 20px; /* Adjust height accordingly */
}

.switch.smaller .slider {
  width: 36px;
  height: 20px;
}

.switch.smaller .slider:before {
  width: 18px;
  height: 18px;
}

.switch.smaller input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>