<template>
  <div id="app">
    <BaseLayout/>
  </div>
</template>

<script>
//import { instance } from './main';
import BaseLayout from './components/BaseLayout.vue';

export default {
  components: {
    BaseLayout,
  },
};
</script>

/* If you want to add styles in future, uncomment and use this section:
<style>
</style>
*/