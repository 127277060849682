<template>
  <div class="login-form">
    <h2>Login</h2>
    <form @submit.prevent="login" method="post">
      <div class="form-group">
        <label for="username">Username or email</label>
        <input type="text" id="username" v-model="username" class="form-control" />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" id="password" v-model="password" class="form-control" />
        <!-- CSRF token and session token as hidden input fields -->
        <input type="hidden" name="csrfToken" :value="csrfToken" />
        <input type="hidden" name="sessionToken" :value="sessionToken" />
      </div>
      <div>
        <button type="submit">Login</button>
        <p>
          <router-link to="/reset-password-request">Forgot Password?</router-link>
        </p>
      </div>
      <div v-if="error" class="error-message">{{ error }}</div>
    </form>
  </div>
</template>

<script>
import { instance } from '../../main';
import { useStore } from 'vuex';
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const isAuthenticated = computed(() => store.state.isAuthenticated);
    const isEmailVerified = computed(() => store.state.isEmailVerified);
    const username = ref('');
    const password = ref('');
    const error = ref('');
    const loading = ref(true);
    const csrfToken = computed(() => store.state.csrfToken);
    const sessionToken = computed(() => store.state.sessionToken);

    onMounted(async () => {
      // Fetch CSRF token and session token
      try {
        const { csrfToken, sessionToken } = await store.dispatch('retrieveTokens');
        loading.value = false;
        // eslint-disable-next-line no-console
        console.log('CSRF Token:', csrfToken);
        // eslint-disable-next-line no-console
        console.log('Session Token:', sessionToken);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching tokens:', error);
      }
    });

    const login = async (event) => {
      event.preventDefault();

      if (loading.value) return;

      let formData = {
        username: username.value,
        password: password.value,
      };

      try {
        const response = await instance.post('/api/login', formData, {
          headers: {
            'X-CSRFToken': csrfToken.value,
            'Content-Type': 'application/json',
          },
        });

        // eslint-disable-next-line no-console
        console.log(JSON.stringify(response.data, null, 2));

        // Check if login was successful
        if (response.data.message === 'Login successful') {
          // Update the isEmailVerified state with the value received from the backend response
          store.commit('setEmailVerifiedStatus', response.data.email_verified);

          // Check if the email is verified before proceeding to the dashboard
          if (isEmailVerified.value) {
            store.commit('setAuthentication', true);
            // Redirect to UserDashboard
            router.push('/dashboard');
          } else {
            // If the email is not verified, show the error message
            error.value = 'You must verify your email before logging in.';
          }
        } else if (response.data.verification_sent) {
          // Show flash message for email verification sent
          error.value = 'Verification email sent. Please check your email to verify your account.';
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        if (err.response) {
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(err.response.data, null, 2));
          // Set the error message based on the response from the backend
          if (err.response.data.errors && err.response.data.errors.form) {
            error.value = err.response.data.errors.form;
          } else if (err.response.data.errors && err.response.data.errors.password) {
            error.value = err.response.data.errors.password;
          } else if (err.response.status === 404) {
            // User not found
            error.value = 'User not found. Please check your username or email.';
          } else if (err.response.status === 401) {
            // Incorrect password
            error.value = 'Incorrect password. Please try again.';
          } else {
            // Other errors
            error.value = 'An error occurred. Please try again later.';
          }
        } else {
          error.value = 'An error occurred. Please try again later.';
        }
      }
    };

    return { isAuthenticated, isEmailVerified, username, password, error, login, loading, sessionToken, csrfToken };
  },
};
</script>

<style scoped>
/* Add component styles here */
.login-form {
  width: 300px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 50px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #384d56;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #c63745;
}
</style>