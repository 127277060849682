<template>
  <h1>Settings</h1>
</template>

<script>

</script>

<style scoped>
/* Add component styles here */
</style>
