import { instance } from './main';

const state = {
  newsFeed: {},
  displayedNewsCount: {},
  newsFeedGenerated: {},
  selectedNews: {},
  filterKeywords: '',
  currentKeywords: {},
  newsCardGenerated: {},
  currentNewsIndex: {},
};

const mutations = {
  SET_NEWS_FEED(state, { keyword, news }) {
    if (!state.newsFeed[keyword]) {
      state.newsFeed[keyword] = []; // Make sure it's reactive by default
    }
    state.newsFeed[keyword] = news;
  },
  INCREMENT_DISPLAYED_NEWS_COUNT(state, keyword) {
    if (!state.displayedNewsCount[keyword]) {
      state.displayedNewsCount[keyword] = 0;
    }
    state.displayedNewsCount[keyword] += 40;
  },
  SET_NEWS_FEED_GENERATED(state, { keyword, value }) {
    if (value === false && state.newsFeedGenerated[keyword]) {
      delete state.newsFeedGenerated[keyword];
    } else {
      state.newsFeedGenerated[keyword] = value;
    }
  },
  SET_SELECTED_NEWS(state, payload) {
    if (payload === null) {
      state.selectedNews = {};
    } else {
      const { keyword, newsItem } = payload;
      state.selectedNews[keyword] = newsItem; // Set the newsItem for the specific keyword
    }
  },
  CLEAR_SELECTED_NEWS(state, keyword) {
    Reflect.deleteProperty(state.selectedNews, keyword); // Delete the property for the specific keyword
  },
  SET_DISPLAYED_NEWS_COUNT(state, { keyword, count }) {
    state.displayedNewsCount[keyword] = count;
  },
  SET_FILTER_KEYWORDS(state, keywords) {
    state.filterKeywords = keywords;
  },
  RESET_FILTER_KEYWORDS(state) {
    state.filterKeywords = '';
  },
  RESET_DISPLAYED_NEWS_COUNT(state) {
    state.displayedNewsCount = {};
  },
  SET_KEYWORD(state, keyword) {
    state.filterKeywords = keyword;
  },
  RESET_NEWS_FEED(state, keyword) {
    if (state.newsFeed[keyword]) {
      delete state.newsFeed[keyword];
    }
  },
  APPEND_NEWS_FEED(state, { keyword, news }) {
    if (!state.newsFeed[keyword]) {
      this.$set(state.newsFeed, keyword, []);
    }
    state.newsFeed[keyword].push(...news); // Directly push to the existing reactive array
  },
  SET_CURRENT_KEYWORD(state, keyword) {
    state.currentKeywords = keyword;
  },
  SET_NEWS_CARD_GENERATED(state, payload) {
    state.newsCardGenerated = { ...state.newsCardGenerated, [payload.keyword]: payload.news };
  },
  SET_CURRENT_NEWS_INDEX(state, { keyword, index }) {
    state.currentNewsIndex[keyword] = index;
  },
};

const actions = {
  async updateKeywordAndFetchNews({ commit, dispatch }, keyword = 'All') {
    commit('SET_KEYWORD', keyword);
    await dispatch('fetchNewsFeed', { keyword });
  },
  async fetchNewsFeed({ commit, state }, { keyword = 'All' }) {
    try {
      if (!state.newsFeed[keyword] || state.newsFeed[keyword].length === 0) {
        commit('SET_NEWS_FEED', { keyword, news: [] });
      }
  
      const lastId = state.newsFeed[keyword].length > 0
                     ? state.newsFeed[keyword][state.newsFeed[keyword].length - 1].id
                     : null;
  
      const response = await instance.get('/api/news-feed', {
        params: {
          limit: state.displayedNewsCount[keyword] || 40,
          last_id: lastId,
          filter: keyword,
        },
      });
  
      if (response.data && response.data.length > 0) {
        commit('APPEND_NEWS_FEED', { keyword, news: response.data });
        commit('INCREMENT_DISPLAYED_NEWS_COUNT', keyword);
        commit('SET_NEWS_FEED_GENERATED', { keyword, value: true }); // Set newsFeedGenerated to true after the news feed is fetched
      }
    } catch (error) {
      console.error(`Error fetching news feed for ${keyword}:`, error);
    }
  },
  setSelectedNews({ commit }, { keyword, newsItem }) {
    commit('SET_SELECTED_NEWS', { keyword, newsItem });
    commit('SET_CURRENT_KEYWORD', keyword);
  },
  async loadMoreNewsTitles({ dispatch, state }, feed) {
    // Check if user has scrolled to the bottom
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      dispatch('fetchNewsFeed', feed || state.filterKeywords);
    }
  },
  selectNextNews({ state, commit }, keyword) {
    const currentNewsFeed = state.newsFeed[keyword];
    let currentIndex = state.currentNewsIndex[keyword] || 0;
    if (currentNewsFeed) {
      const nextIndex = (currentIndex + 1) % currentNewsFeed.length;
      commit('SET_SELECTED_NEWS', { keyword, newsItem: currentNewsFeed[nextIndex] });
      commit('SET_CURRENT_NEWS_INDEX', { keyword, index: nextIndex });
    }
  },
  async generateNewsFeed({ commit }, keyword) {
    commit('SET_FILTER_KEYWORDS', keyword); // Update filterKeywords
    await this.dispatch('news/fetchNewsFeed', keyword);
  },
  setCurrentKeyword({ commit }, payload) {
    commit('SET_CURRENT_KEYWORD', payload);
  },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
