<!--
-------------------------------------------------------------------------------------------
This component represents a news box that displays options to generate and filter news feed,
and links to news feeds.
-------------------------------------------------------------------------------------------
Props:
- isOpen: Boolean indicating whether the box is open or not.

Data:
- railItem: Object with a default title of 'News', representing the selected rail item.
- filterKeywords: String representing the filter keywords for news feed filtering.

Computed Properties:
- selectedRailItem: Selected rail item from the 'rail' state module.
- newsFeed: Array of news items from the 'news' state module.

Usage:
This component is used to display options related to the news feed. It includes a filter input to apply keyword-based filtering to the news feed. The 'Generate News Feed' button triggers the 'fetchNewsFeed' action. It also provides links to different news summaries. The component communicates with the Vuex store to manage the filter keywords and the news feed.
-->

<template>
  <BaseBox :isOpen="isOpen" :railItem="railItem" @close="closeBox">
    <div class="news-box">
      <div class="news-group">
        <h3 class="section-title">News Feed</h3>
        <input
          class="filter-input"
          type="text"
          v-model="currentInput"
          placeholder="Apply filter (comma separated keywords)"
          :disabled="!isOpen"
        />
        <button
          class="generate-button"
          @click="generateNewsFeed"
          :disabled="!isOpen"
        >
          Generate News Feed
        </button>
      </div>
      <hr class="divider" />
      <div class="news-group">
        <h3 class="section-title">News Summary</h3>
        <router-link to="/dashboard/news/daily-summary" class="summary-link">
          Daily Summary
        </router-link>
        <router-link to="/dashboard/news/weekly-summary" class="summary-link">
          Weekly Summary
        </router-link>
        <router-link
          to="/dashboard/news/monthly-summary"
          class="summary-link"
        >
          Monthly Summary
        </router-link>
      </div>
    </div>
  </BaseBox>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import BaseBox from './BaseBox.vue';

export default {
  components: {
    BaseBox,
  },
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      railItem: { title: 'News' },
      currentInput: '', // Local state to hold the input until submission
    };
  },
  computed: {
    ...mapState('rail', ['selectedRailItem']),
    ...mapState('news', ['newsFeed', 'filterKeywords']),
  },
  methods: {
    ...mapMutations('rail', ['closeBox']),
    ...mapActions('news', ['fetchNewsFeed']),
    generateNewsFeed() {
      const keyword = this.currentInput.trim() || 'All';  // Default to 'All' if input is empty
      this.$store.dispatch('news/updateKeywordAndFetchNews', keyword);
      this.currentInput = ''; // Clear the input field after dispatching
    },
    updateFilterKeywords(newKeywords) {
      this.$store.commit('news/SET_FILTER_KEYWORDS', newKeywords);
    },
  },
};
</script>

<style scoped>
.news-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #384d56; /* Adjust font color */
}

.section-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.feed-option {
  display: block;
  margin-bottom: 5px;
}

.filter-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.generate-button {
  background-color: #384d56;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.generate-button:hover {
  background-color: #c63745;
}

.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.summary-link {
  display: block;
  margin-bottom: 5px;
  color: #c63745;
  text-decoration: none;
}

.summary-link:hover {
  text-decoration: underline;
}

.news-card {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
}

</style>
