<template>
  <div class="root-page">
    <h1>Welcome to Crypto Fundamentalist</h1>
      <br /><br />
      <p>Here you can build your personalized Dashboard and efficiently access the information that matters to you. Charts, data, the latest news, whitepapers, sentiment analysis, and much more!</p>
      <br />
      <p>Make informed decisions no matter what your angle is. Are you an investor, a trader, a web3 developer, an entrepreneur, a crypto and blockchain enthusiast, or all maybe all of them combined?</p>
      <br />
      <p>Crypto Fundamentalist is for you! </p>
      <br /><br />
      <p>Register and login to access the full functionality.</p>
  </div>
</template>

<script>

</script>

<style scoped>
.root-page {
  margin: 5px;
  padding: 10px;

}
</style>
