<template>
  <div v-if="isSettingsDisplayCardOpen" class="settings-display-card-container">
    <div class="settings-display-card-content">
      <button class="close-button" @click="closeCard">X</button>
      <h3 class="section-title">Display Options</h3>
      <p>
        We have noticed that your device has a current width of {{ screenWidth }}.
        For this device, you have the following options apart from the current view mode:
      </p>

      <!-- Display the options -->
      <div v-for="option in availableDisplayOptions" :key="option" @click="handleOptionClick(option)">
        <img :src="getOptionIcon(option)" alt="" />
        <span>{{ option }}</span>
      </div>

    </div>
    <hr class="divider" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState('rail', ['selectedRailItem']),
    ...mapState('settings', ['screenWidth', 'isSettingsDisplayCardOpen']),
    ...mapGetters('settings', ['availableDisplayOptions']),
  },
  methods: {
    ...mapMutations('settings', ['CLOSE_SETTINGS_CARD']),
    ...mapActions('settings', ['updateScreenWidth', 'setAvailableDisplayOptions', 'closeSettingsDisplayCard']),
    closeCard() {
      this.CLOSE_SETTINGS_CARD();
    },
    changeDisplaySettings() {
      // eslint-disable-next-line no-console
      console.log('Change Display Settings called');
      const screenWidth = this.screenWidth;
      // eslint-disable-next-line no-console
      console.log('Current Screen Width:', screenWidth);
      const availableOptions = this.availableDisplayOptions(screenWidth);
      // eslint-disable-next-line no-console
      console.log('Available Options:', availableOptions);
      this.setAvailableDisplayOptions(availableOptions);
    },
    getOptionIcon(option) {
      return require(`../../assets/customComponents/DisplayOption${option}.png`).default;
    },
    handleOptionClick(option) {
      // eslint-disable-next-line no-console
      console.log(`Option ${option} clicked`);
    },
  },
};

</script>

<style scoped>
.settings-display-card-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-display-card-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90%;
}

.close-button {
  position: right top;
  margin: 50px 350px;
  top: 7px;
  right: 7px;
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  padding: 10px 10px;
  border-radius: 25%;
  cursor: pointer;
}

</style>
