<template>
    <div v-if="tooltip.visible" class="tooltip" 
      :style="tooltipStyle" 
      @mouseover="mouseOverTooltip" 
      @mouseleave="mouseLeaveTooltip"
    >
        <h3>{{ tooltip.content.title }}</h3>
        <p>{{ tooltip.content.author }}</p>
        <p>{{ tooltip.content.publisher }}</p>
        <p>{{ tooltip.content.publication_date }}</p>
        <a :href="tooltip.content.url" target="_blank" class="amazon-link">Shop in Amazon</a>
        <p v-if="fullDescriptionShown">{{ tooltip.content.description }}</p>
        <p v-else>{{ truncatedDescription }}</p>
        <button v-if="!fullDescriptionShown" @click="showFullDescription">Read more</button>
  </div>
</template>
  
  <script>
  import { mapState } from 'vuex';

  export default {
    props: {
      book: Object,
      style: Object,
    },
    data() {
      return {
        fullDescriptionShown: false,
      };
    },
    computed: {
      ...mapState('search', ['tooltip']),
      tooltipStyle() {
        const position = this.$store.state.search.tooltip.position;
        return {
            position: 'fixed',
            left: `${position.left}`,
            top: `${position.top}`,
            // Other styles as needed
        };
      },
      truncatedDescription() {
        if (this.fullDescriptionShown || !this.tooltip.content.description || this.tooltip.content.description.length < 100) {
            return this.tooltip.content.description;
        } else {
            return this.tooltip.content.description.substring(0, 100) + '...';
        }
      },
      showReadMore() {
        return !this.fullDescriptionShown && this.tooltip.content.description && this.tooltip.content.description.length > 100;
      },
    },
    methods: {
      mouseOverTooltip() {
        this.$store.dispatch('search/setCursorOnTooltip', true);
      },
      mouseLeaveTooltip() {
        this.$store.dispatch('search/setCursorOnTooltip', false);
        this.$store.dispatch('search/hideTooltip');
      },
      showFullDescription() {
        this.fullDescriptionShown = true;
        // Prevent the tooltip from hiding when "Read more" is clicked.
        this.$emit('mouseOverTooltip');
      },
      setCursorOnTooltip(value) {
        this.$store.dispatch('search/setCursorOnTooltip', value);
      },
    },
  };
  </script>
  
  <style>
  .tooltip {
    font-size: 11px;
    z-index: 5000; /* Ensure it's above other content */
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    max-width: 350px;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    /* Additional styling as needed */
  }
    .amazon-link {
    display: inline-block;
    background-color: #FF9900; /* Amazon's brand color */
    color: white;
    padding: 8px 12px;
    margin-top: 10px;
    border-radius: 4px;
    text-decoration: none;
  }
  .amazon-link:hover {
    background-color: #cc7a00;
  }
  </style>
  