import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Ensure this import is correct
import axios from 'axios';
import { CookieJar } from 'tough-cookie';
import { registerLicense } from '@syncfusion/ej2-base';
import PortalVue from 'portal-vue';

// Register Syncfusion License key
registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5SdEBiWntbcHBWQWRa');

// Import Vuetify styles and components
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';

// Create the Vuetify instance
const vuetify = createVuetify({
  components,
  directives,
});

// Create a new Axios instance with a custom cookie jar
const cookieJar = new CookieJar();
export const instance = axios.create({
  baseURL: 'http://localhost:5000/',
  withCredentials: true, // enable cookies with Axios
  jar: cookieJar,
});

// Add a response interceptor to handle the session cookie and CSRF token
instance.interceptors.response.use((response) => {
  const csrfToken = response.headers['X-CSRFToken'];
  if (csrfToken) {
    store.commit('updateCsrfToken', csrfToken);
    console.log('Updating CSRF token in Vuex:', csrfToken);
  }
  return response;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor to handle the session cookie and receive user info
instance.interceptors.response.use((response) => {
  console.log('Response data:', response.data);
  return response;
}, (error) => {
  return Promise.reject(error);
});

// Fetch CSRF token initially and store it in the Vuex state
console.log('Before dispatching retrieveTokens action');
store.dispatch('retrieveTokens')
  .then(({ csrfToken, sessionToken }) => {
    console.log('CSRF token retrieved at main.js:', csrfToken);
    console.log('Session token retrieved at main.js:', sessionToken);
    console.log('Vuex state after main.js commit:', store.state);

    // Fetch user info
    return store.dispatch('users/fetchUserInfo');
  })
  .then(() => {
    // Listen for screen resizes events and update vuex state
    window.addEventListener('resize', () => {
      store.commit('settings/UPDATE_SCREEN_WIDTH', window.innerWidth);
    });

    // Create the Vue app instance
    const app = createApp(App);
    app.use(store);
    app.use(router);
    app.use(vuetify);
    app.use(PortalVue);

    // Mount the Vue app with Vuetify
    app.mount('#app');
  })
  .catch((error) => {
    console.error('Error retrieving CSRF token:', error);

    const app = createApp(App);
    app.use(store);
    app.use(router);
    app.use(vuetify);
    app.use(PortalVue);

    // Mount the Vue app with Vuetify
    app.mount('#app');
  });