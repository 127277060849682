export const state = {
  topZIndex: 1500,
  openedComponents: [],
  componentCounter: 0,
};

export const mutations = {
  bringToFront(state, componentId) {
    state.topZIndex++;
    const component = state.openedComponents.find(c => c.id === componentId);
    if (component) {
      component.zIndex = ++state.topZIndex;
    }
  },
  incrementCounter(state) {
    state.componentCounter++;
    console.log('Component counter incremented. Current count:', state.componentCounter);
  },
  decrementCounter(state) {
    if (state.componentCounter > 0) {
      state.componentCounter--;
      console.log('Component counter decremented. Current count:', state.componentCounter);
    }
  },
  addComponent(state, component) {
    console.log('Adding component to state:', component);
    state.openedComponents.push(component);
    console.log('Current opened components:', state.openedComponents.length);
  },
  removeComponent(state, componentId) {
    console.log('Removing component from state:', componentId);
    state.openedComponents = state.openedComponents.filter(c => c.id !== componentId);
    console.log('Component removed. Current opened components:', state.openedComponents.length);
  },
  updateComponentZIndex(state, { index, zIndex }) {
    if (state.openedComponents[index]) {
      state.openedComponents[index] = {
        ...state.openedComponents[index],
        zIndex: zIndex
      };
    }
  },
  UPDATE_COMPONENT_ID(state, { oldId, newId }) {
    const index = state.openedComponents.findIndex(c => c.id === oldId);
    if (index !== -1) {
      state.openedComponents = [
        ...state.openedComponents.slice(0, index),
        { ...state.openedComponents[index], id: newId },
        ...state.openedComponents.slice(index + 1)
      ];
    }
  },
};

export const getters = {
  getComponentZIndex: (state) => (componentId) => {
    const component = state.openedComponents.find(c => c.id === componentId);
    return component ? component.zIndex : 0;
  },
  maxComponents: (_, __, ___, rootGetters) => {
    return rootGetters['users/maxComponents'];
  }
};

export const actions = {
  async addComponentIfAllowed({ state, getters, commit }, component) {
    console.log('Attempting to add component:', component.id);

    // Check if component already exists in the state
    const existingComponent = state.openedComponents.find(c => c.id === component.id);
    if (existingComponent) {
      console.warn(`Component with id ${component.id} already exists in the state.`);
      return false;
    }

    // Check if the component count is within the limit
    if (state.openedComponents.length < getters.maxComponents) {
      commit('addComponent', component);
      commit('incrementCounter');
      console.log('Component added successfully:', component.id);
      return true;
    } else {
      alert(`You have reached the limit of ${getters.maxComponents} components allowed in your plan. Please close one or more to request new components.`);
      return false;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
