<template>
  <h1>Reset Password</h1>
  <form @submit="requestResetPassword" class="reset-password-request-form">
    <div class="form-group">
      <label for="email">Email</label>
      <input type="text" id="email" v-model="email" size="32">
    </div>
    <button type="submit">Reset Password</button>
  </form>
  <div v-if="error" class="error-message">{{ error }}</div>
  <div v-if="success" class="success-message">{{ success }}</div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { instance } from '../../main';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const email = ref('');
    const error = ref('');
    const success = ref('');
    const router = useRouter();

    // Function to convert string to snake case
    const toSnakeCase = (str) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

    // Function to transform the keys of an object to snake case
    const transformKeysToSnakeCase = (obj) => {
      const transformedObj = {};
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          transformedObj[toSnakeCase(key)] = obj[key];
        }
      }
      return transformedObj;
    };

    const requestResetPassword = async (event) => {
      event.preventDefault();

      // simple email validation
      if (!email.value || !email.value.includes('@')) {
        error.value = 'Invalid email address';
        return;
      }

      const formData = {
        email: email.value,
      };

      const formDataSnakeCase = transformKeysToSnakeCase(formData);

      try {
        // Get the CSRF token and session token from the Vuex store
        const csrfToken = store.getters.csrfToken;
        const sessionToken = store.getters.sessionToken;

        // Set the headers for the request
        const headers = {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
          'Authorization': `Bearer ${sessionToken}`,
        };

        // Make the API request using Axios and the configured instance
        const response = await instance.post('/api/reset-password-request', formDataSnakeCase, { headers });

        // eslint-disable-next-line no-console
        console.log(JSON.stringify(response.data, null, 2));

        // Reset form field, show success message, and clear any previous error
        email.value = '';
        success.value = 'Password reset email sent. Please check your email inbox.';
        error.value = '';

        // Redirect the user to the homepage after a delay (e.g., 2 seconds)
        setTimeout(() => {
          router.push('/');
        }, 2000); // 2000 milliseconds = 2 seconds

      } catch (err) {
        // Handle error response
        success.value = '';
        // eslint-disable-next-line
        error.value = err.response.data.message;
      }
    };

    return {
      email,
      error,
      success,
      requestResetPassword,
    };
  },
};
</script>

<style scoped>
/* Add component styles here */
.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}
</style>
