import { createRouter, createWebHistory } from 'vue-router';
import store from './store.js';

import RootPage from './components/RootPage.vue';
import LoginForm from './components/auth/LoginForm.vue';
import RegistrationForm from './components/auth/RegistrationForm.vue';
import ResetPasswordRequest from './components/auth/ResetPasswordRequest.vue';
import ResetPassword from './components/auth/ResetPassword.vue';
import UserProfile from './components/profile/UserProfile.vue';
import UserSettings from './components/profile/UserSettings.vue';
import ChangePassword from './components/profile/ChangePassword.vue';
import UserDashboard from './components/dashboard/UserDashboard.vue';
import EmailVerification from './components/auth/EmailVerification.vue';
import NewsCard from './components/dashboard/NewsCard.vue';
import NewsTitleList from './components/dashboard/NewsTitleList.vue';
import ChartsCanddleChart from './components/dashboard/ChartsCandleChart.vue';
import SearchCard from './components/dashboard/SearchCard.vue';

const routes = [
  {
    path: '/',
    name: 'root',
    component: RootPage,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginForm,
  },
  {
    path: '/register',
    name: 'register',
    component: RegistrationForm,
  },
  {
    path: '/reset-password-request',
    name: 'reset-password',
    component: ResetPasswordRequest,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password-token',
    component: ResetPassword,
  },
  {
    path: '/profile',
    name: 'profile',
    component: UserProfile,
  },
  {
    path: '/user-settings',
    name: 'settings',
    component: UserSettings,
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword,
  },
  {
    path: '/dashboard/:module?',
    component: UserDashboard,
    props: true,
    meta: { requiresAuth: true },
    children: [
      { path: '', component: NewsTitleList },
      { path: 'news/:id', component: NewsCard, props: true },
      { path: 'charts/:id', component: ChartsCanddleChart, props: true },
      { path: 'search/:id', component: SearchCard, props: true },
    ],
  },
  {
    path:'/verification/:token',
    name: 'verification',
    component: EmailVerification,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  const requiresAuth = to.matched.some(record => record.path.startsWith('/dashboard'));

  // Check if the user is not authenticated
  if (requiresAuth && !store.state.isAuthenticated) {
    // Redirect the user to the login page
    next({ name: 'login' });
  } else {
    // Proceed to the route
    next();
  }
});


export default router;