import { instance } from './main';

export const state = {
  maxComponents: 0, // Default value
};

export const mutations = {
  setMaxComponents(state, maxComponents) {
    state.maxComponents = maxComponents;
  },
};

export const actions = {
  async fetchUserInfo({ commit }) {
    try {
      const response = await instance.get('/api/user_info');
      const { max_components } = response.data;
      commit('setMaxComponents', max_components);
    } catch (error) {
      console.error('Error fetching user info:', error);
      throw error;
    }
  },
};

export const getters = {
  maxComponents: state => state.maxComponents,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};