<template>
  <BaseBox :railItem="railItem" :isOpen="isOpen" @close="closeBox">
    <div class="charts-box">
      <div class="charts-group">

        <h3 class="section-title">Trading Pair</h3>
        <base-autocomplete
          currencyType="base"
          :suggestions="baseCurrencySuggestions"
          v-model="selectedBaseCurrency"
          @input="baseInputValue = $event; selectedBaseCurrency = $event"
          @update:inputValue="value => baseInputValue = value"
          @selectSuggestion="suggestion => selectSuggestion(suggestion, 'base')"
          :availableBaseCurrencies="getAvailableBaseCurrencies"
          placeholderText="Select Base Currency"
        ></base-autocomplete>
        <base-autocomplete
          currencyType="quote"
          :suggestions="quoteCurrencySuggestions"
          v-model="selectedQuoteCurrency"
          @input="quoteInputValue = $event; selectedQuoteCurrency = $event"
          @update:inputValue="value => quoteInputValue = value"
          @selectSuggestion="suggestion => selectSuggestion(suggestion, 'quote')"
          :availableQuoteCurrencies="getAvailableQuoteCurrencies"
          placeholderText="Select Quote Currency"
        ></base-autocomplete>
        <hr class="divider" />

        <h3 class="section-title">Timeframe</h3>
        <label v-for="timeframe in timeframes" :key="timeframe">
          <input type="radio" :value="timeframe" v-model="selectedTimeframe" /> <span class="option-label">{{ timeframe }}</span>
        </label>
        <hr class="divider" />

        <h3 class="section-title">Chart Model</h3>
        <label v-for="model in chartModels" :key="model">
          <input type="radio" :value="model" v-model="selectedChartModel" /> <span class="option-label">{{ model }}</span>
        </label>
        <hr class="divider" />

        <button
          class="generate-chart-button"
          @click="generateChart"
          :disabled="!isOpen"
        >
          Generate Chart
        </button>
      </div>
    </div>
  </BaseBox>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import BaseBox from './BaseBox.vue';
import BaseAutocomplete from '../BaseAutocomplete.vue';

export default {
  components: {
    BaseBox,
    'base-autocomplete': BaseAutocomplete,
  },
  props: {
    isOpen: Boolean,
  },
  watch: {
    selectedBaseCurrency(newVal) {
      if (newVal) {
        this.$store.commit('charts/SET_SELECTED_BASE_CURRENCY', { chartId: this.selectedChartId, name: newVal, code: newVal });
      }
    },
    selectedQuoteCurrency(newVal) {
      if (newVal) {
        this.$store.commit('charts/SET_SELECTED_QUOTE_CURRENCY', { chartId: this.selectedChartId, name: newVal, code: newVal });
      }
    },
    selectedTimeframe(newVal) {
      this.$store.commit('charts/SET_SELECTED_TIMEFRAME', { chartId: this.selectedChartId, timeframe: newVal });
    },
  },
  computed: {
    ...mapState('rail', ['selectedRailItem']),
    ...mapGetters({
      getAvailableBaseCurrencies: 'charts/getAvailableBaseCurrencies',
      getAvailableQuoteCurrencies: 'charts/getAvailableQuoteCurrencies'
    }),
    selectedChartId() {
      const baseCurrencyCode = this.selectedBaseCurrency ? this.selectedBaseCurrency.code : '';
      const quoteCurrencyCode = this.selectedQuoteCurrency ? this.selectedQuoteCurrency.code : '';
      const timeFrame = this.selectedTimeframe ? this.selectedTimeframe : '';
      const chartType = this.selectedChartModel.replace(/\s+/g, ''); // Remove spaces from chart type

      return baseCurrencyCode + quoteCurrencyCode + timeFrame + chartType;
    },
  },
  data() {
    return {
      railItem: { title: 'Charts' },
      baseCurrencySuggestions: [],
      quoteCurrencySuggestions: [],
      selectedBaseCurrency: '',
      selectedQuoteCurrency: '',
      baseInputValue: '',
      quoteInputValue: '',
      timeframes: ['1m', '5m', '15m', '1H', '4H', '12H', '1d', '3d', '1W', '2W', '1M', '3M', '1Y'],
      selectedTimeframe: '1m', // Default to '1m'
      chartModels: ['Candle Chart', 'Hi-Lo Chart'],
      selectedChartModel: 'Candle Chart', // Default to 'Candle Chart'
    };
  },
  methods: {
    ...mapMutations('rail', ['closeBox']),
    ...mapActions('charts', ['fetchChartData', 'updateSelectedChartId']),
    filterBaseCurrencySuggestions(query) {
      if (!query) {
        this.baseCurrencySuggestions = []; // Reset suggestions if query is empty
        return;
      }

      const lowerCaseQuery = query.toLowerCase();
      const availableBaseCurrencies = this.getAvailableBaseCurrencies;
      this.baseCurrencySuggestions = availableBaseCurrencies.filter((currency) => {
        // Check if the currency name or code contains the query
        return (
          currency.name.toLowerCase().includes(lowerCaseQuery) ||
          currency.code.toLowerCase().includes(lowerCaseQuery)
        );
      });

      if (this.baseCurrencySuggestions.length === 0) {
        this.baseCurrencySuggestions.push('Currency not available');
      }
    },

    filterQuoteCurrencySuggestions(query) {
      if (!query) {
        this.quoteCurrencySuggestions = []; // Reset suggestions if query is empty
        return;
      }

      const lowerCaseQuery = query.toLowerCase();
      const availableQuoteCurrencies = this.getAvailableQuoteCurrencies;
      this.quoteCurrencySuggestions = availableQuoteCurrencies.filter((currency) => {
        // Check if the currency name or code contains the query
        return (
          currency.name.toLowerCase().includes(lowerCaseQuery) ||
          currency.code.toLowerCase().includes(lowerCaseQuery)
        );
      });

      if (this.quoteCurrencySuggestions.length === 0) {
        this.quoteCurrencySuggestions.push('Currency not available');
      }
    },

    selectSuggestion({ name, code }, type) {
      if (!code) {
        console.error(`Code is undefined for currency ${name}`);
        return;
      }

      if (type === 'base') {
        this.selectedBaseCurrency = { name, code };
        this.$store.commit('charts/SET_SELECTED_BASE_CURRENCY', { chartId: this.selectedChartId, name, code });
      } else if (type === 'quote') {
        this.selectedQuoteCurrency = { name, code };
        this.$store.commit('charts/SET_SELECTED_QUOTE_CURRENCY', { chartId: this.selectedChartId, name, code });
      }
    },

    async generateChart() {
      const chartId = this.selectedChartId;
      console.log('Generated Chart ID:', chartId); // Log the generated chart ID
      if (chartId) {
        this.$store.dispatch('charts/updateSelectedChartId', chartId);
        this.$store.commit('charts/SET_SELECTED_TIMEFRAME', this.selectedTimeframe);

        await this.$store.dispatch('charts/fetchChartData', {
          chartId: this.selectedChartId,
          baseCurrency: this.selectedBaseCurrency.code,
          quoteCurrency: this.selectedQuoteCurrency.code,
          timeframe: this.selectedTimeframe,
        });

        console.log("Adding component to state after data fetch:", { id: chartId, zIndex: this.$store.state.components.topZIndex });
        this.$store.commit('components/addComponent', { id: chartId, zIndex: this.$store.state.components.topZIndex });
        this.$store.commit('components/incrementCounter');
      } else {
        console.error('Please select all required options.');
      }
    },
  },
};
</script>

<style scoped>
.section-title {
  font-size: 18px;
  color: black;
  margin-bottom: 10px;
}

.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.generate-chart-button {
  background-color: #384d56;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.generate-chart-button:hover {
  background-color: #c63745;
}

label {
  margin-right: 10px;
}

.option-label {
  color: black;
}
</style>