<!--
-------------------------------------------------------------------------------------------
This component represents a base box that displays content related to a selected rail item.
It is used as a container for different tools or features associated with the rail item.
-------------------------------------------------------------------------------------------
Props:
- isOpen: Boolean indicating whether the box is open or not.
- railItem: Object representing the selected rail item.

Usage:
This component is used as a wrapper for displaying different tools or features associated
with the selected rail item. The content of the box is customized based on the rail item.
It emits the 'close' event when the close button is clicked, indicating that the parent
component should close the box.
-->


<template>
  <div class="base-box" v-if="isOpen">

    <!-- Close button -->
    <div class="base-box-header">
      <h2 class="box-title">{{ railItem.title }} Tools</h2>
      <button class="close-button" @click="closeBox">X</button>
    </div>

    <!-- Content of the base box -->
    <div class="content">
      <!-- Display rail item content -->
      <div class="item-content">
        <!-- Customize content based on the rail item -->
        <slot></slot>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  props: {
    isOpen: Boolean, // Whether the box is open or not
    railItem: Object, // The selected rail item
  },

  methods: {
    closeBox() {
      // Emit an event to notify the parent component to close the box
      this.$emit('close');
    },
  },
};

</script>


<style scoped>

.base-box {
  width: 20vw; /* % of the viewport width */
  min-width: 350px;
  height: 75%; /* Same height as the rail bar */
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 120px; /* Adjust this value to match the rail bar width */
  margin-top: 110px; /* Margin between rail bar and box */
  z-index: 50000; /* Make sure the box is on top of other content */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.box-title {
  flex-grow: 1; /* Allow the title to take available space */
  font-size: 15px;
  margin-right: 10px; /* Add some spacing between title and close button */
  color: white; /* White text color */
}

.close-button {
  background-color: #c63745;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.base-box-header {
  background-color: #384d56;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.content {
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 95%; /* Adjust as needed */
}

</style>
