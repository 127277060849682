<!--
-----------------------------------------------------------------------------------------
This component represents a navigation rail bar.
-----------------------------------------------------------------------------------------
Data:
- railItems: Array of rail items with icons, titles, and routes.

Computed Properties:
- selectedRailItem: Index of the selected rail item.
- isOpen: Boolean indicating whether a base box is open.

Methods:
- navigateToRailItem: Navigates to the selected rail item.
- navigateTo: Navigates to a specific route.
- closeBox: Closes the currently open base box.

Usage:
This component provides a vertical navigation rail bar that allows users to switch between different sections of the dashboard. Each rail item has an associated icon and title. Clicking on a rail item changes the selected section and updates the displayed content. The selected rail item is highlighted, and clicking on it displays a corresponding base box content. The base box content can vary based on the selected rail item and can include various components such as NewsBox, Charts, Analysis, etc.
-->


<template>
  <div class="rail-bar">
    <div
      v-for="(item, index) in railItems"
      :key="index"
      @click="navigateToRailItem(index)"
      :class="['rail-item', { 'rail-item-selected': selectedRailItem === index }]"
    >
      <v-icon :class="{ 'selected-icon': selectedRailItem === index }">{{ item.icon }}</v-icon>
      <span class="rail-item-title">{{ item.title }}</span>
    </div>

    <!-- Display BaseBox when isOpen is true -->
    <BaseBox :isOpen="isOpen" :railItem="railItems[selectedRailItem]" @close="closeBox">

      <!-- Conditional rendering for the specific rail item content -->
      <template v-if="selectedRailItem === 2">
        <NewsBox :isOpen="isOpen" />
      </template>

      <template v-else>
        <!-- Render other components for different rail items -->
        <template v-if="selectedRailItem === 6">
          <SettingsBox :isOpen="isOpen" />
        </template>

        <template v-if="selectedRailItem === 3">
          <ChartsBox :isOpen="isOpen" />
        </template>

        <template v-if="selectedRailItem === 1">
          <SearchBox :isOpen="isOpen" />
        </template>

        <div>Default Content</div>
      </template>
    </BaseBox>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import BaseBox from './BaseBox.vue';
import NewsBox from './NewsBox.vue';
import SearchBox from './SearchBox.vue';
import SettingsBox from './SettingsBox.vue';
import ChartsBox from './ChartsBox.vue';

export default {
  components: {
    BaseBox,
    NewsBox,
    SearchBox,
    SettingsBox,
    ChartsBox,
  },
  data() {
    return {
      railItems: [
        { icon: 'mdi-account-circle', title: 'Profile', route: '/dashboard/profile' },
        { icon: 'mdi-magnify', title: 'Search', route: '/dashboard/search' },
        { icon: 'mdi-newspaper-variant-multiple', title: 'News', route: '/dashboard/news' },
        { icon: 'mdi-chart-line', title: 'Charts', route: '/dashboard/charts' },
        { icon: 'mdi-chart-scatter-plot', title: 'Analysis', route: '/dashboard/analysis' },
        { icon: 'mdi-database-search-outline', title: 'Data', route: '/dashboard/data' },
        { icon: 'mdi-cog-outline', title: 'Settings', route: '/dashboard/settings' },
        // Add more items as needed
      ],
    };
  },
  computed: {
    ...mapState('rail', ['selectedRailItem', 'isOpen']),
    selectedRailItemData() {
      return this.railItems[this.selectedRailItem];
    }
  },
  watch: {
    newsFeedGenerated(newValue) {
      if (newValue) {
        this.closeBox();
        }
      },
    },
  methods: {
    ...mapMutations('rail', ['updateSelectedRailItem', 'closeBox', 'setRailIsOpen']),

    navigateToRailItem(index) {
      this.updateSelectedRailItem(index);
      this.navigateTo(this.railItems[index].route);
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    generateNewsFeed() {
      this.setRailIsOpen(false); // Close RailBar (NewsModule)
    },
  },
};
</script>

<style>
/* Your styles here */
</style>


<style>

.rail-bar {
  width: 70px; /* Adjust width as needed */
  background-color: #384d56;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  position:relative;
  z-index: 50000;
  border-radius: 4px;
}

.rail-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  gap: 8px;
}

.rail-item:hover {
  background-color: #333;
}

.rail-item-selected {
  background-color: #4444;
  border-left: 2px solid #c63745;
  padding: 10px;
  border-radius: 0 8px 8px 0;
}

.rail-item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px; /* Adjust font size for item title */
}

.selected-icon {
  color: #c63745;
}
</style>