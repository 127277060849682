<!--
------------------------------------------------------------------------------------------
This component displays a list of latest news article titles.
------------------------------------------------------------------------------------------
Computed Properties:
- displayedNewsCount: Number of displayed news titles.
- newsFeedGenerated: Boolean indicating whether the news feed has been generated.
- newsFeed: Array of fetched news articles.

Data:
- displayedNews: Array to store displayed news titles.
- filterKeywords: String containing filter keywords.

Methods:
- loadMoreNews: Loads more news titles as the user scrolls.
- generateNewsFeed: Generates the news feed.
- handleScroll: Handles scroll event to load more news titles.
- closeNewsList: Closes the news title list and resets selected news.
- openNewsCard: Opens a detailed view of a news article.
- updateFilterKeywords: Updates the filter keywords for news search.

Usage:
This component displays a list of news article titles, allowing the user to scroll to load more titles. It is used to provide a quick overview of available news articles. Users can click on a title to open a detailed view of the news article using the NewsCard component. The news feed is generated when the component is mounted, and it can be filtered using filter keywords.
-->

<template>
  <div @click="onComponentClicked" v-if="newsFeedGenerated && isNewsTitleListAllowed">
    <Vue3DraggableResizable
      :style="{ zIndex: getComponentZIndex }"
      :draggable="isGlobalDraggable"
      :dragHandle="'.drag-handle'"
      :w="450"
      :h="600"
      :x="400"
      :y="300"
      :resizable="true"
      :keyword="keyword"
    >
    <div class="title-header">
      <h2 class="section-title">{{ dynamicTitle }}</h2>
      <button class="close-button" @click.stop="closeNewsList">X</button>
    </div>  
      <div class="news-title-list" @scroll="handleScroll">           
        <ul class="title-list">
          <li v-for="newsItem in newsTitles" :key="newsItem.id" class="title-item">
            <a @click.stop="openNewsCard(newsItem)">{{ newsItem.title }}</a>
          </li>
        </ul>
      </div>
    
    </Vue3DraggableResizable>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Vue3DraggableResizable from 'vue3-draggable-resizable';
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css';
//import { EventBus } from '../../EventBus.js'

/* eslint-disable */

export default {

  props: ['keyword'],
  
  computed: {
    ...mapState('news', ['displayedNewsCount', 'newsFeedGenerated', 'newsFeed', 'filterKeywords']),
    ...mapState(['isGlobalDraggable']),
    ...mapState('components', ['openedComponents', 'componentCounter']),
    dynamicTitle() {
      return this.keyword ? `News Feed - ${this.keyword}` : 'News Feed - All';
    },
    getComponentZIndex() {
      const component = this.openedComponents.find(c => c.id === 'news-title-list-' + this.keyword);
      return component ? component.zIndex : 0;
    },
    newsTitles() {
      return this.$store.state.news.newsFeed[this.keyword] || [];
    },
    isNewsTitleListAllowed() {
      const maxComponents = this.$store.getters['components/maxComponents'];
      const currentOpenedComponents = this.$store.state.components.openedComponents.length;
      const isCurrentlyOpened = this.$store.state.components.openedComponents.some(c => c.id === 'news-title-list-' + this.keyword);

      // Allow if the component is already opened or if adding a new one doesn't exceed the limit
      return isCurrentlyOpened || currentOpenedComponents < maxComponents;
    },
  },
  
  components: {
    Vue3DraggableResizable,
  },

  data() {
    return {
      loadingMoreNews: false,
    };
  },

  methods: {
    ...mapActions('news', ['fetchNewsFeed', 'setSelectedNews', 'updateKeywordAndFetchNews', 'loadMoreNewsTitles']),
    handleScroll() {
      // Throttle or debounce this method
      let container = this.$el.querySelector('.news-title-list');
      if (container && container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
        if (!this.loading) {
          this.loading = true;
          // Get the current active feed
          const activeFeed = this.$store.state.activeFeed;
          // Load more news titles based on the active feed
          this.loadMoreNewsTitles(activeFeed).then(() => {
            this.loading = false;
          });
        }
      }
    },
    closeNewsList() {
      const keyword = this.keyword || 'All'; 
      this.$store.commit('news/RESET_NEWS_FEED', keyword);
      this.$store.commit('news/SET_NEWS_FEED_GENERATED', { keyword, value: false });
      this.$store.dispatch('news/setSelectedNews', { keyword, newsItem: null});
      this.$store.commit('components/removeComponent', `news-title-list-${this.keyword}`);
      this.$store.commit('components/decrementCounter');
      this.$store.commit('news/SET_DISPLAYED_NEWS_COUNT', { keyword, count: 0 }); // Reset displayedNewsCount
    },
    openNewsCard(newsItem) {
      console.log('Emitting select event with keyword', this.keyword);
      this.$store.commit('news/SET_NEWS_CARD_GENERATED', { keyword: this.keyword, news: newsItem });
      this.$store.dispatch('news/setSelectedNews', { keyword: this.keyword, newsItem: newsItem });
      this.$router.push({ path: `/dashboard/news/${newsItem.id}` });
      this.selectNewsItem(); // emit 'keyword' to the parent component
    },
    onComponentClicked() {
      const dynamicId = `news-title-list-${this.keyword}`;
      this.$store.commit('components/bringToFront', dynamicId);
    },
    async generateNewsFeed() {
      const keyword = this.keywords || 'All';
      this.$store.commit('news/SET_FILTER_KEYWORDS', keyword);
      await this.$store.dispatch('news/updateKeywordAndFetchNews', this.keyword);
    },
    selectNewsItem() {
      console.log('Emitting select event with keyword', this.keyword);
      this.$emit('select', this.keyword);
    },
    updateNewsFeedGenerated(newValue) {
      if (!newValue) {
        this.$store.commit('news/SET_DISPLAYED_NEWS_COUNT', 40); // Reset to default value
        this.$store.commit('news/RESET_FILTER_KEYWORDS', ''); // erase used keywords once the news title list is closed
        this.$store.commit('news/RESET_NEWS_FEED'); // eliminate all the objects from the array when news title list is closed
      }
    },
  },

  watch: {
    keyword(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.commit('news/SET_FILTER_KEYWORDS', newValue);
        this.generateNewsFeed(newValue);
      }
    },
    newsFeedGenerated(newValue) {
      this.updateNewsFeedGenerated(newValue);
    },
  },
  
  async mounted() {
  console.log('Checking if component is allowed:', this.isNewsTitleListAllowed);
  if (!this.isNewsTitleListAllowed) {
    console.log('Component limit reached, cannot add more components.');
    return; // Exit early if not allowed to add more components
  }
  console.log('newsFeed', this.newsFeed);
  const dynamicId = `news-title-list-${this.keyword}`;
  console.log('Dynamic ID:', dynamicId);
  const component = {
    id: dynamicId,
    zIndex: this.$store.state.components.topZIndex,
    type: 'news-title-list',
    keyword: this.keyword
  };  
  
  console.log('Attempting to add component:', component);
  const canAddComponent = await this.$store.dispatch('components/addComponentIfAllowed', component);
  console.log('Can add component:', canAddComponent);
  if (canAddComponent) {
    this.$store.commit('components/bringToFront', dynamicId);
    console.log('Component added and brought to front.');
  }
  else {
    console.log('Cannot add component due to max limit reached.');
  }
},

  beforeUnmount() {
    this.$store.commit('news/CLEAR_SELECTED_NEWS', this.keyword);
    this.$store.commit('components/removeComponent', `news-title-list-${this.keyword}`);
    this.$store.commit('components/decrementCounter');
  },
};

</script>

<style scoped>
.news-title-list {
  padding: 15px;
  background-color: #f7f7f7;
  border: 3px solid #384d56;
  border-radius: 2px;
  max-height: 100%;
  min-height: 300px;
  overflow-y: scroll;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.title-header {
  font-size: 18px;
  color: #fff;
  background-color: #384d56;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  cursor: move;
}

.title-list {
  list-style: none;
  padding: 10px;
  margin-top: 10px;
  padding-top: 10px;
}

.title-item {
  margin-bottom: 10px;
  cursor: pointer
}

.title-item a {
  text-decoration: underline;
  color: #333;
  font-weight: semi-bold;

}

.close-button {
  background-color: #c63745;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5%;
  cursor: pointer;
}

</style>
