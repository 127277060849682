<template>
  <div>
    <input
      v-model="inputValue"
      :placeholder="placeholderText"
      @input="filterSuggestions"
      @blur="autoCompleteField"
    />
    <ul v-if="filteredSuggestions.length">
      <li v-for="suggestion in filteredSuggestions" :key="suggestion.code" @click="selectSuggestion(suggestion)">
        {{ suggestion.name }} ({{ suggestion.code }})
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: String,
    currencyType: String, // 'base' or 'quote'
    placeholderText: String,
    availableBaseCurrencies: Array,
    availableQuoteCurrencies: Array,
  },
  data() {
    return {
      inputValue: '', // Use inputValue to store user input
      filteredSuggestions: [],
    };
  },
  watch: {
    availableBaseCurrencies(newValue) {
      console.log('availableBaseCurrencies:', newValue);
    },
    availableQuoteCurrencies(newValue) {
      console.log('availableQuoteCurrencies:', newValue);
    },
  },
  computed: {
    ...mapGetters('charts', ['getAvailableBaseCurrencies', 'getAvailableQuoteCurrencies']),
  },
  methods: {
    filterSuggestions() {
      this.filteredSuggestions = [];
      if (!this.inputValue) return;

      let currenciesToFilter = [];
      if (this.currencyType === 'base') {
        currenciesToFilter = this.getAvailableBaseCurrencies;
      } else if (this.currencyType === 'quote') {
        currenciesToFilter = this.getAvailableQuoteCurrencies;
      }

      const matches = currenciesToFilter.filter(currency =>
        currency.name.toLowerCase().includes(this.inputValue.toLowerCase()) ||
        currency.code.toLowerCase() === this.inputValue.toLowerCase()
      );

      this.filteredSuggestions = matches.length > 0 ? matches : ['Currency not available'];
    },

    selectSuggestion(suggestion) {
      if (suggestion !== 'Currency not available') {
        this.inputValue = suggestion.name || suggestion.code; // Update the input value directly
        this.$emit('input', this.inputValue); // Emit to update inputValue in parent

        // Emit the full suggestion object instead of just the code
        this.$emit('selectSuggestion', suggestion);

        this.filteredSuggestions = []; // Clear suggestions
      }
    },

    autoCompleteField() {
      if (this.filteredSuggestions.length === 1 && this.filteredSuggestions[0] !== 'Currency not available') {
        const selectedCurrency = this.filteredSuggestions[0];
        this.$emit('input', selectedCurrency);

        // Use v-model to update inputValue in the parent component
        this.$emit('update:inputValue', selectedCurrency.name || selectedCurrency.code);

        // Clear suggestions
        this.filteredSuggestions = [];
      }
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
}

li {
  cursor: pointer; /* Make the suggestions clickable */
  color: black; /* Set the color to black */
  padding: 5px; /* Add some padding for better appearance */
}

li:hover {
  background-color: #eee; /* Highlight on hover */
}
</style>