// Settings module related store

const state = {
  screenWidth: window.innerWidth,
  isSettingsDisplayCardOpen: false, // Add a state for the card open status
  selectedDisplayOption: '',
};

const getters = {
  availableDisplayOptions(state) {
    // Define available options based on screen width ranges
    if (state.screenWidth < 500) {
      return [0]; // For mobile screens
    } else if (state.screenWidth < 1024) {
      return [0, 1, 2, 3]; // For medium screens
    } else if (state.screenWidth < 1290) {
      return [0, 1, 2, 3, 4]; // For medium-large screens
    } else {
      return [0, 1, 2, 3, 4, 5]; // For wide monitors
    }
  },
};

const mutations = {
  UPDATE_SCREEN_WIDTH(state, width) {
    state.screenWidth = width;
  },
  SET_SETTINGS_DISPLAY_CARD_OPEN(state, isOpen) {
    state.isSettingsDisplayCardOpen = isOpen;
  },
  CLOSE_SETTINGS_CARD(state) {
    state.isSettingsDisplayCardOpen = false;
  },
};

const actions = {
  setSettingsDisplayCardOpen({ commit }, isOpen) {
    commit('SET_SETTINGS_DISPLAY_CARD_OPEN', isOpen);
  },
  updateScreenWidth({ commit }, width) {
    commit('UPDATE_SCREEN_WIDTH', width);
  },
  closeSettingsDisplayCard({ commit }) {
    commit('CLOSE_SETTINGS_CARD');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};