// store/railModule.js
const state = {
  selectedRailItem: 0, // Default to the first item
  isOpen: false, // Initially, the box is closed
};

const mutations = {
  updateSelectedRailItem(state, newIndex) {
    state.selectedRailItem = newIndex;
    state.isOpen = true; // Open the box when a rail item is selected
  },
  closeBox(state) {
    state.isOpen = false; // Close the box
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
