<template>
  <div>
    <h2>Reset Password</h2>
    <form @submit="resetPassword">
      <div>
        <label for="password">New Password</label>
        <input type="password" id="password" v-model="password" />
      </div>
      <div>
        <label for="confirmPassword">Confirm Password</label>
        <input type="password" id="confirmPassword" v-model="confirmPassword" />
      </div>
      <div>
        <button type="submit">Reset Password</button>
      </div>
      <div v-if="success" class="success-message">{{ success }}</div>
      <div v-if="error" class="error-message">{{ error }}</div>
    </form>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { instance } from '../../main';
import { useStore } from 'vuex';

export default {
  setup() {
    const password = ref('');
    const confirmPassword = ref('');
    const error = ref('');
    const success = ref('');
    const router = useRouter();
    const store = useStore(); // Access the Vuex store

    const resetPassword = async (event) => {
      event.preventDefault();

      // Check if passwords match
      if (password.value !== confirmPassword.value) {
        error.value = 'Passwords do not match';
        return;
      }

      // Check if password is too short
      if (password.value.length < 8) {
        error.value = 'Password is too short';
        return;
      }

      // Get the token from the URL
      const token = router.currentRoute.value.params.token;

      try {
        // Get the CSRF token and session token from the Vuex store
        const csrfToken = store.getters.csrfToken;
        const sessionToken = store.getters.sessionToken;

        // Set the headers for the request
        const headers = {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
          'Authorization': `Bearer ${sessionToken}`,
        };

        // Make the API request to the correct endpoint with the token
        const response = await instance.post(`/api/reset-password/${token}`, { password: password.value, confirm_password: confirmPassword.value }, { headers });

        // eslint-disable-next-line no-console
        console.log(JSON.stringify(response.data, null, 2));

        // Reset form fields and show success message
        password.value = '';
        confirmPassword.value = '';
        success.value = 'Password reset successfully';
        error.value = '';

        // Redirect the user to the homepage after a delay (e.g., 2 seconds)
        setTimeout(() => {
          router.push('/');
        }, 2000); // 2000 milliseconds = 2 seconds

      } catch (err) {
        // Handle error response
        success.value = '';
        error.value =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'An error occurred during the request.';
      }
    };

    return {
      password,
      confirmPassword,
      error,
      success,
      resetPassword,
    };
  },
};
</script>

<style scoped>
/* Add component styles here */
.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}
</style>