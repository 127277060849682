<template>
  <h1>Change Password</h1>
  <form @submit="changePassword" class="change-password-form">
    <div class="form-group">
      <label for="current-password">{{ form.current_password.label() }}</label>
      <input type="password" id="current-password" v-model="currentPassword" class="form-control">
    </div>
    <div class="form-group">
      <label for="new-password">{{ form.new_password.label() }}</label>
      <input type="password" id="new-password" v-model="newPassword" class="form-control">
    </div>
    <div class="form-group">
      <label for="confirm-password">{{ form.confirm_password.label() }}</label>
      <input type="password" id="confirm-password" v-model="confirmPassword" class="form-control">
    </div>
    <button type="submit" class="btn btn-primary">Change Password</button>
  </form>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const currentPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const error = ref('');
    const success = ref('');

    const changePassword = async (event) => {
      event.preventDefault();

      // Verify if the new password and confirm password match
      if (newPassword.value !== confirmPassword.value) {
        error.value = "New password and confirmed password do not match";
        return;
      }

      try {
        await axios.post('/api/change-password', {
          currentPassword: currentPassword.value,
          newPassword: newPassword.value
        });

        success.value = 'Password updated successfully';
        error.value = '';
        // Clear the form
        currentPassword.value = '';
        newPassword.value = '';
        confirmPassword.value = '';
      } catch (err) {
        // Handle error
        error.value = err.response.data.message;
        success.value = '';
      }
    };

    return {
      currentPassword,
      newPassword,
      confirmPassword,
      error,
      success,
      changePassword
    };
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 1em;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}
</style>